import 'firebase/remote-config';
import firebase from 'firebase/app';
let firebaseConfig;
const prod_domain = 'halu.love';
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfig_halu = {
    apiKey: "AIzaSyBY8_6fc4cQdnPRilZR8RT-NHK-6P3dHcc",
    authDomain: "halulive-81627.firebaseapp.com",
    projectId: "halulive-81627",
    storageBucket: "halulive-81627.appspot.com",
    messagingSenderId: "709372222704",
    appId: "1:709372222704:web:7897cf1798fb04ab01f635",
    measurementId: "G-T6N6SGDL6K"
  };

const firebaseConfig_test = {
    apiKey: "AIzaSyBuDISeLcd3MGzuGo5IlkeX0ojlkRGicus",
    authDomain: "my-project-1081c.firebaseapp.com",
    projectId: "my-project-1081c",
    storageBucket: "my-project-1081c.appspot.com",
    messagingSenderId: "472241193364",
    appId: "1:472241193364:web:efe418587b0de5825b4f81",
    measurementId: "G-5MCJLM4BGS"
};
// Initialize Firebase
if (!firebase.apps.length) {
    // Give the different Firebase project for develop and test dependent on hostname.
    firebaseConfig = window.location.hostname === prod_domain
        ? firebaseConfig_halu
        : firebaseConfig_test
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app(); // if already initialized, use that one
}

export const remoteConfig = firebase.remoteConfig();

remoteConfig.settings = {
    minimumFetchIntervalMillis: 10000
}
// Set defaultConfig false to disable login/purchase feature to users.
// Set newebpay_outage true to switch users to purchase on Paynow instead.
remoteConfig.defaultConfig = ({
    'dev_enableLoginAndPurchase': true,
    'prod_enableLoginAndPurchase': true,
    'dev_newebpay_outage': false,
    'prod_newebpay_outage': false,
});
import axios from 'axios';
import { setErrorMsg, setSnackbarOpen } from '../actions';
import store from '../store'
import { clearUserInfo } from '../UserState';
import CryptoJS from 'crypto-js'

// Headers
const isSafariHeader = "is-safari";
const accessTokenHeader = "access_token";
export const xCsrfTokenHeader = "x-csrf-token";

// LocalStorages
export const accessTokenKey = "access_token";
export const xCsrfTokenKey = "x-csrf-token";
// privateKey must be 8/16/32 digits
const privateKey = '=?tYNdhbD#2em4Sc'
export function setLocalStorageEncrypt(key, value) {
  const keyHex = CryptoJS.enc.Utf8.parse(privateKey)
  let encrypted = CryptoJS.AES.encrypt(value, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }).toString();
  if (localStorage != null) {
    localStorage.setItem(key, encrypted)
  }
  return ""
}
function getLocalStorageDecrypt(key) {
  const value = localStorage != null ? localStorage.getItem(key) : "";
  const keyHex = CryptoJS.enc.Utf8.parse(privateKey)
  if (!value) return
  let decrypted = CryptoJS.AES.decrypt(value, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
    .toString(CryptoJS.enc.Utf8);
  return decrypted;
}
export function rmLocalStorage(key) {
  if (localStorage != null) {
    localStorage.removeItem(key)
  }
}
export const instance = axios.create({
  // from 'public/env-config.js' which is generated by env.sh
  baseURL: window._env_.API_URL,
  headers: {},
  withCredentials: true,
})

instance.interceptors.request.use(function (config) {
  // Our official website domain is different from api's domain
  // private mode doesn't allow cross site cookies by default
  // we provide the access token in the header (Stored as a localStorage after the user logged in)
  config.headers[isSafariHeader] = true;
  config.headers[xCsrfTokenKey] = getLocalStorageDecrypt(xCsrfTokenKey);
  const accessToken = getLocalStorageDecrypt(accessTokenKey)
  if (accessToken !== undefined && accessToken !== '') {
    config.headers[accessTokenHeader] = accessToken;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
export const apiGetEvnet = (data) => instance.get('/web/getActivityList', data);
export const apiGetEventBanner = (data) => instance.get('/server/getAdvertisement', { params: { type: 2 } }, data);
export const apiGetRechargeList = (data) => instance.get(`/web/getRechargeList`, data);
export const apiGetIncomeDayRank = (data) => instance.get('/web/incomeDayRank', data);
export const apiGetActivity = (id) => instance.get('/web/activities/' + id);
export const apiGetUserInfo = () => instance.get('/user/selfInfo', { params: { is_web: 1 } });
export const apiLoginPhone = (data) => instance.post('/web/auth/login/phone', data);
export const apiPhoneCaptcha = (data) => instance.post('/web/auth/login/phone/captcha', data);
export const apiThirdPartyLogin = (data) => instance.post('/web/auth/login/thirdparty', data);
export const apiUserLogout = (data) => instance.post('/web/auth/logout', data);
export const apiBuyRechargeNewebPay = (data) => instance.post(`/web/payments/newebpay`, data);
export const apiBuyRechargePaynow = (data) => instance.post(`/web/payments/paynow`, data);
export const apiBuyRechargeSunpay = (data) => instance.post(`/web/payments/sunpay`, data);
export const apiBuyRechargeSinopac = (data) => instance.post(`/web/payments/sinopac`, data);
export const apiBuyRechargeGomypay = (data) => instance.post(`/web/payments/gomypay`, data);
export const apiPaymentGetLovecodeInfo = () => instance.get('/web/payments/info/lovecode');
export const apiPaymentInfoPaynow = (data) => instance.get('/web/payments/info/paynow', data);
export const apiModifyUserInfo = (data) => instance.patch('/web/payments/info/paynow', data);
export const apiGetGiftsList = (data) => instance.get('/gift', { params: { list_type: 'live', is_web: 1 } }, data);
export const apiModifyPhonePwd = (data) => instance.patch('/web/auth/login/phone/password', data);
export const apiGetUserInfoAnony = (id) => instance.get('/user/userInfo', { params: { account: id, is_web: 1 } });
export const apiGetLiveList = (data) => instance.get('/live/getLiveList', { params: { type: 6, index: 0, count: 10 } }, data)

// NeWebPay
const newWebPayURL = 'https://core.newebpay.com';
export const newWebPayMPGAPI = newWebPayURL + '/MPG/mpg_gateway';

// paynow
const payNowURL = 'https://www.paynow.com.tw';
export const payNowEpointAPI = payNowURL + '/service/etopm.aspx';

// sunpay
const sunpayURL = 'https://www.esafe.com.tw';
export const sunpayEpointAPI = sunpayURL + '/Service/Etopm.aspx';

// sinopac
const sinopacURL = "https://epos.sinopac.com"; // production
// const sinopacURL = "https://eposuat.sinopac.com"; // testing
export const sinopacAPI = sinopacURL + '/HPPRequest';

// sinopac fun
export const sinopacFunATMAPI = window._env_.API_URL+'/pay/sinopacFun/atm';

// gomypay
const gomypayURL = 'https://n.gomypay.asia';
export const gomypayAPI = gomypayURL + '/ShuntClass.aspx';

// v.1 purchase page
// original payment page topUpV3.html will be redirected to /purchase/newebpay
export const version1PurchaseURL = 'https://halu.love/purchase';
export const newebpayOutageURL = 'https://halu.love/purchase';

// Error handling
const respCodeSuccess = 0;
const respCodeInvalidToken = 1;
export function handleAPICall(error) {
  // for debug
  // console.log('api call error', error)
  if (error.response) {
    // 在不正確的 status 時會進入此
    store.dispatch(setSnackbarOpen())
    store.dispatch(setErrorMsg(`錯誤 ${error.response.status} ${error.response.data.error? error.response.data.error : error.response.data.msg}，請稍後再試`))
  } else if (error.request) {
    // 網路中斷時會進入此
    store.dispatch(setSnackbarOpen())
    store.dispatch(setErrorMsg(`網路不穩定，請稍後再試`))
  } else {
    // 在設定 request 時出錯會進入此
    store.dispatch(setSnackbarOpen())
    store.dispatch(setErrorMsg(`${error.message?error.message: error.error} 發送請求失敗，請稍後再試`))
  }
  return null
}
export function handleRespCode(resp) {
  if (resp.code === respCodeSuccess) {
    return true
  } else {
    if (resp.code === respCodeInvalidToken) {
      clearUserInfo()
    }
    store.dispatch(setSnackbarOpen())
    store.dispatch(setErrorMsg(`code: ${resp.code}，${resp.msg}`))
    return false
  }
}
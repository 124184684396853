import React, { Fragment, memo, useState } from 'react';
import { css, cx } from '@emotion/css';
import { Redirect, Route, Switch } from 'react-router-dom';
import Download from './pages/Download';
import Events from './pages/Events';
import Home from './pages/Home';
import Policies from './pages/Policies';
import Purchase from './pages/Purchase';
import ContactUs from './pages/ContactUs';
import EventPages from './pages/Events/EventPages';
import Footer from './Footer';
import WxLogin from './Login/WxLogin';
import WxloginResponse from './Login/WxLoginResp';
import { paymentTypeNewebPay, paymentTypePayNow, paymentTypeSunpay } from './pages/Purchase/Const'
import { NewebpayRedirect, PaynowRedirect, SunpayRedirect, paymentRedirect } from './pages/Purchase/Redirect'
import Gifts from './pages/Gifts';
import LiveRoom from './pages/LiveList';

const Body = ({ className, setShowAppBar, setShowAppBarTabs, isPurchaseRouters }) => {
    const [showFooter, setShowFooter] = useState(true)
    return (
        <Fragment>
            <section
                className={cx(
                    className,
                    css`
                        display: flex;
                        flex-direction: column;
                    ${'' /* background: linear-gradient(to bottom right, #2a2a2a, #000); */}
                    `)}
            >
                {/* <Toolbar /> */}
                <main
                    className={css`
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    background: linear-gradient(to bottom right, #2a2a2a, #000);
                 `}
                >
                    {/* TODO: create an array as params for mapping Routes https://github.com/remix-run/react-router/issues/4551 */}
                    {isPurchaseRouters ?
                        <Switch>
                            <Route path="/" component={Home} exact strict />
                            <Route path="/events" component={Events} exact strict />
                            {/* <Route path="/events/:eventID" component={(props) => {
                                setShowAppBar(false)
                                return (
                                    <EventPages {...props}></EventPages>
                                )
                            }} exatrict /> */}
                            {/* <Route path="/gifts" component={Gifts} exact strict /> */}
                            <Route path="/download" component={Download} exact strict />
                            {/* <Route path="/wxlogin_qrcode" component={WxLogin} exact strict />
                            <Route path="/wxlogin_response" component={WxloginResponse} exact strict /> */}
                            <Route path="/policies" component={(props) => {
                                setShowAppBar(false)
                                return (
                                    <Policies {...props}></Policies>
                                )
                            }} exact strict />
                            <Route path="/contactus" component={ContactUs} exact strict />
                            <Route path="/contact-us" component={(props) => {
                                // contact-us pages will be opened in iOS and Android APPs
                                // We need to hide them to provent reviewers accessing to Purchase pages,
                                // or our APPs may not approved for releases.
                                setShowAppBar(false)
                                setShowFooter(false)
                                return (
                                  <ContactUs {...props}></ContactUs>
                                )
                            }} exact strict />
                            {/* TODO: create a config for enable live room feature */}
                            {/* <Route path="/live_room" component={LiveRoom} exact strict /> */}
                            <Route path="/purchase"
                                component={Purchase}
                                exact
                                strict
                            />
                            <Route path="/purchase/redirect" component={paymentRedirect} exact strict />
                            <Redirect to="/" />
                        </Switch>
                        :
                        <Switch>
                            <Route path="/" component={Home} exact strict />
                            <Route path="/events" component={Events} exact strict />
                            {/* <Route path="/events/:eventID" component={(props) => {
                                setShowAppBar(false)
                                return (
                                    <EventPages {...props}></EventPages>
                                )
                            }} exatrict /> */}
                            {/* TODO: path for each live_id such as EventPages */}
                            {/* <Route path="/live_room" component={LiveRoom} exact strict /> */}
                            {/* <Route path="/gifts" component={Gifts} exact strict /> */}
                            <Route path="/download" component={Download} exact strict />
                            {/* <Route path="/wxlogin_qrcode" component={WxLogin} exact strict />
                            <Route path="/wxlogin_response" component={WxloginResponse} exact strict /> */}
                            <Route path="/policies" component={(props) => {
                                setShowAppBar(false)
                                return (
                                    <Policies {...props}></Policies>
                                )
                            }} exact strict />
                            <Route path="/contactus" component={ContactUs} exact strict />
                            <Route path="/contact-us" component={(props) => {
                                // contact-us pages will be opened in iOS and Android APPs
                                // We need to hide them to provent reviewers accessing to Purchase pages,
                                // or our APPs may not approved for releases.
                                setShowAppBar(false)
                                setShowFooter(false)
                                return (
                                    <ContactUs {...props}></ContactUs>
                                )
                            }} exact strict />
                            <Redirect to="/" />
                        </Switch>
                    }
                </main>
                {showFooter && <Footer />}
            </section>
        </Fragment>
    );
};

export default memo(Body);

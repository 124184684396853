export function validIdTaxNumber(buyerUbn) {
    var invalidList = "00000000, 11111111";
    if (/^\d{8}$/.test(buyerUbn) === false || invalidList.indexOf(buyerUbn) !== -1) {
        return false
    }
    var validateOperator = [1, 2, 1, 2, 1, 2, 4, 1],
        sum = 0,
        calculate = function (product) { // 個位數 + 十位數
            var ones = product % 10,
                tens = (product - ones) / 10;
            return ones + tens;
        };
    for (var i = 0; i < validateOperator.length; i++) {
        sum += calculate(buyerUbn[i] * validateOperator[i]);
    }
    return (sum % 10 === 0 || (buyerUbn[6] === '7' && (sum + 1) % 10 === 0));
}
export function validEmail(userEmail) {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(userEmail) && userEmail.length < 50
}
export function validName(userName) {
    var regex = /^[\u4e00-\u9fa5_A-Za-z ]{2,60}$/;
    return regex.test(userName);
};
export function paynowValidName(userName) {
    if (userName !== '') {
        var regex = /^[\u4e00-\u9fa5A-Za-z ]{1,20}$/;
        return regex.test(userName)
    }
    return true
}

export function validPhone(userPhone) {
    var phoneneno = /^\+?\d{1,20}$/;
    return userPhone.match(phoneneno) !== null
}
export function isEmailError(selectedValue, email, user_email) {
    return (selectedValue === email) && !validEmail(user_email)
}

export function isEmailValid(user_email) {
    if (user_email !== '') {
        return validEmail(user_email)
    }
    return true
}
export function isUbnError(buyer_ubn, buyer_name) {
    if (buyer_ubn !== '') {
        return !validIdTaxNumber(buyer_ubn)
    }
    return false
}
export function isNameError(buyer_ubn, buyer_name) {
    if (buyer_name !== '') {
        return !validName(buyer_name)
    }
    return false
}

export function isValidPhoneCarrier(carrier) {
    // - 需放最後，不然要會跟 0-9 的 - 混淆
    const regex = /^\/[0-9A-Z.+-]{7}$/;
    return regex.test(carrier)
}

export function isValidCDCCarrier(carrier) {
    const regex = /^[A-Z]{2}[0-9]{14}$/;
    return regex.test(carrier)
}
import { css, cx } from '@emotion/css';
import React, { memo } from 'react';

const menuButtonCss = css`
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  height: unset;
  &::before {
    content: '';
    padding-bottom: 100%;
    display: block;
  }
`;

const burgerContainerCss = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const burgerCss = css`
  width: 62.5%;
  height: 7.5%;
  background: white;
  border-radius: calc(500% / 24);
  box-shadow: 0 2px 5px rgba(3, 155, 299, 0.2);
  transition: all 0.3s ease-in-out;

  &::before,
  ::after {
    content: '';
    position: absolute;
    width: 62.5%;
    height: 7.5%;
    background: white;
    border-radius: calc(500% / 24);
    box-shadow: 0 2px 5px rgba(3, 155, 299, 0.2);
    transition: all 0.5s ease-in-out;
  }
  &::before {
    transform: translateY(-200%);
  }
  &::after {
    transform: translateY(200%);
  }
`;

const openBurgerCss = css`
  background: transparent;
  box-shadow: none;
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const BurgerMenu = ({ className, size = '24px', onClick, open }) => {
  return (
    <div
      className={cx(
        menuButtonCss,
        css`
          width: ${size};
        `
      )}
      onClick={onClick}
    >
      <div className={burgerContainerCss}>
        <div className={cx(burgerCss, { [openBurgerCss]: open })}></div>
      </div>
    </div>
  );
};

export default memo(BurgerMenu);

import React, { useState, useRef, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Button, } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import {
    apiBuyRechargeSinopac,
    apiPaymentGetLovecodeInfo,
    sinopacAPI,
    sinopacFunATMAPI,
    handleAPICall,
    handleRespCode,
} from '../../service/api';
import { useLoveCode, email, sinopacPayTypeCreditCard, sinopacPayTypeUnionPay, sinopacPayTypeATM } from './Const';
import { isEmailError, isUbnError, isNameError } from './isValid';

const useStyles = makeStyles((theme) => ({
    form: {
        fontSize: '.6rem',
    },
    text: {
        padding: '2px',
        color: 'black',
    },
    textInputColor: {
        color: 'black'
    },
    loveCode: {
        padding: '2px',
        color: '#007bff',
    },
    button: {
        lineHeight: '3',
        marginTop: '1rem',
        border: 'none',
        color: '#ffffff',
        backgroundColor: 'black',
        "&:hover": { color: '#ffffff', backgroundColor: 'black', },
    },
    placeholder: {
        color: '#cc0000',
    },
    helperText: {
        color: '#cc0000',
        height: '50px',
    },
}));
const SinopacForm = ({ id, payType, ...props }) => {
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = useState(useLoveCode);
    const [user_email, setUserMail] = useState('');
    const [buyer_ubn, setBuyerUbn] = useState('');
    const [buyer_name, setBuyerName] = useState('');
    const [use_love_code, setUseLoveCode] = useState(selectedValue === useLoveCode);
    const [inputDisable, setInputDisable] = useState(true);
    const [isEmailRequired, setEmailRequired] = useState(false);
    const [helperTextUbn, setHelperTextUbn] = useState('')
    const [helperTextName, setHelperTextName] = useState('')
    const [loveCodeInfo, setLoveCodeInfo] = useState('');
    const handleChangeRadio = (e) => {
        if (selectedValue !== email) {
            setUseLoveCode(false)
            setSelectedValue(e.target.value)
            setInputDisable(false)
            setEmailRequired(true)
        } else {
            setHelperTextUbn('')
            setHelperTextName('')
            setUseLoveCode(true)
            setSelectedValue(e.target.value)
            setInputDisable(true)
            setUserMail('')
            setBuyerName('');
            setBuyerUbn('');
            setEmailRequired(false)
        }
    };
    useEffect(() => {
        isUbnError(buyer_ubn, buyer_name) ? setHelperTextUbn('買受人統一編號，純數字') : setHelperTextUbn('')
        if (!isNameError(buyer_ubn, buyer_name)) {
            setHelperTextName('')
        } else {
            if (buyer_name.length < 2 || buyer_name.length > 60) {
                setHelperTextName('發票抬頭長度限制 60 字元')
            } else {
                setHelperTextName('發票抬頭不可包含數字及特殊字元')
            }
        }
    }, [buyer_ubn, buyer_name])

    useEffect(() => {
        apiPaymentGetLovecodeInfo()
            .then((res) => res.data)
            .then((res) => {
                if (handleRespCode(res) === false) {
                    return
                } else {
                    setLoveCodeInfo(res.name + "(" + res.lovecode + ")")
                }
            })
            .catch((error) => handleAPICall(error))
    }, [])

    function validateForm() {
        if (selectedValue !== email) {
            return true
        } else
            return !isUbnError(buyer_ubn, buyer_name) && !isNameError(buyer_ubn, buyer_name) && !isEmailError(selectedValue, email, user_email)
    }
    const sinopac_form = useRef(null);
    const sinopac_form_mid = useRef(null);
    const sinopac_form_tid = useRef(null);
    // const sinopac_form_installment = useRef(null);
    // const sinopac_form_customIp = useRef(null);
    const sinopac_form_oid = useRef(null);
    const sinopac_form_transAmt = useRef(null);
    const sinopac_form_transMode = useRef(null);
    const sinopac_form_memberId = useRef(null);
    const sinopac_form_frontendUrl = useRef(null);
    const sinopac_form_backendUrl = useRef(null);
    const sinopacFormSubmit = (data) => {
        sinopac_form_mid.current.value = data.mid;
        sinopac_form_tid.current.value = data.tid;
        sinopac_form_oid.current.value = data.oid;
        sinopac_form_transAmt.current.value = data.transAmt;
        sinopac_form_transMode.current.value = data.transMode;
        sinopac_form_memberId.current.value = data.memberId;
        sinopac_form_frontendUrl.current.value = data.frontendUrl;
        sinopac_form_backendUrl.current.value = data.backendUrl;
        sinopac_form.current.submit();
    }
    const sinopac_fun_atm_form = useRef(null);
    const sinopac_fun_atm_form_order_no = useRef(null);
    const sinopacFunAtmFormSubmit = (orderNo) => {
        sinopac_fun_atm_form_order_no.current.value = orderNo;
        sinopac_fun_atm_form.current.submit();
    }
    const onBuySubmit = async (recharge_id) => {
        // empty strings are invalid params, so we only send corresponding params to the server
        let params = {
            recharge_id,
            pay_type: payType,
            use_love_code,
        }
        if (!use_love_code) {
            params.user_email = user_email;
            if (buyer_ubn !== '' || buyer_name !== '') {
                params.buyer_ubn = buyer_ubn
                params.buyer_name = buyer_name
            }
        }
        await apiBuyRechargeSinopac(params)
            .then((res) => res.data)
            .then((res) => {
                if (handleRespCode(res) === false) {
                    return
                } else {
                    if (payType === sinopacPayTypeCreditCard || payType === sinopacPayTypeUnionPay) {
                        sinopacFormSubmit(res)
                    } else {
                        if (payType === sinopacPayTypeATM) {
                            sinopacFunAtmFormSubmit(res.orderNo)
                        } else {
	                        window.location.replace(res.payUrl);
                        }
                    }
                }
            })
            .catch((error) => handleAPICall(error))
    };
    return (
        <>
            <Radio
                checked={selectedValue === useLoveCode}
                onChange={handleChangeRadio}
                value={useLoveCode}
                color="default"
            />
            <span className={classes.text}>
               捐贈至
            </span>
            <span className={classes.loveCode}>{loveCodeInfo}</span><br />
            <Radio
                checked={selectedValue === email}
                onChange={handleChangeRadio}
                value={email}
                color="default"
            />
            <span className={classes.text}>
                    電子信箱
            </span>
            <form className={classes.form} >
                <TextField
                    style={{ height: '50px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor
                        }
                    }}
                    required={isEmailRequired}
                    disabled={inputDisable}
                    id={user_email}
                    label="請輸入Email"
                    type={email}
                    value={user_email}
                    error={isEmailError(selectedValue, email, user_email)}
                    fullWidth
                    onChange={(e) => setUserMail(e.target.value)}
                />
                <TextField
                    style={{ height: '50px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor
                        }
                    }}
                    disabled={inputDisable}
                    margin="normal"
                    id="name"
                    label="請輸入統編 ( 選填 ) "
                    placeholder="買受人統一編號，純數字"
                    type="number"
                    value={buyer_ubn}
                    error={isUbnError(buyer_ubn, buyer_name)}
                    fullWidth
                    FormHelperTextProps={{
                        className: classes.helperText
                    }}
                    helperText={helperTextUbn}
                    onChange={(e) => setBuyerUbn(e.target.value)}
                />
                <TextField
                    style={{ height: '50px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor
                        }
                    }}
                    disabled={inputDisable}
                    margin="normal"
                    id="title"
                    label="請輸入發票抬頭 ( 選填 ) "
                    placeholder="發票抬頭長度限制 60 字元"
                    type="name"
                    value={buyer_name}
                    error={isNameError(buyer_ubn, buyer_name)}
                    fullWidth
                    FormHelperTextProps={{
                        className: classes.helperText
                    }}
                    helperText={helperTextName}
                    onChange={(e) => setBuyerName(e.target.value)}
                />
            </form>

            <Button
                type="submit"
                onClick={() => onBuySubmit(id)}
                disabled={!validateForm()}
                fullWidth
                className={classes.button}
                variant="contained"
            >
                前往付款
            </Button>

            <form id="sinopac_form" ref={sinopac_form} name='sinopac_epos' method='post' action={sinopacAPI}>
                <input type='hidden' ref={sinopac_form_mid} id="mid" name='mid' value='' />
                <input type='hidden' ref={sinopac_form_tid} id="tid" name='tid' value='' />
                <input type='hidden' ref={sinopac_form_oid} id="oid" name='oid' value='' />
                <input type='hidden' ref={sinopac_form_transAmt} id="transAmt" name='transAmt' value='' />
                <input type='hidden' ref={sinopac_form_transMode} id="transMode" name='transMode' value='' />
                <input type='hidden' ref={sinopac_form_memberId} id="memberId" name='memberId' value='' />
                <input type='hidden' ref={sinopac_form_frontendUrl} id="frontendUrl" name='frontendUrl' value='' />
                <input type='hidden' ref={sinopac_form_backendUrl} id="backendUrl" name='backendUrl' value='' />
            </form>
            <form id="sinopac_fun_atm_form" ref={sinopac_fun_atm_form} name='sinopac_fun_atm' method='post' action={sinopacFunATMAPI}>
                <input type='hidden' ref={sinopac_fun_atm_form_order_no} id="order_no" name='orderNo' value='' />
            </form>
        </>
    )
};

export default SinopacForm;

import React, { useState, useEffect } from 'react';
import { Button, Dialog } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
    setStateLogin,
    setStateLogout,
    setUserName,
    setUserAvatar,
    setUserDiamond,
    setLoginDialogOpen,
    setLoginDialogClose,
} from './actions';
import {
    apiUserLogout,
    apiGetUserInfo,
    handleAPICall,
    xCsrfTokenKey,
    accessTokenKey,
    rmLocalStorage,
    handleRespCode,
} from './service/api';
import LoginPopup from './Login/LoginPage';
import store from './store';
const useStyles = makeStyles((theme) => ({
    small: {
        margin: '0 1rem',
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    large: {
        marginBottom: '1rem',
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: '1.5rem',
        borderRadius: '10px',
        backgroundColor: '#EBECF0',
        minWidth: '150px',
        maxWidth: '500px',
        boxShadow: '0 0 .5rem rgba(0, 0, 0, .27)',
    },
    userName: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '150px',
        minHeight: '40px',
        fontWeight: '700',
        color: '#3e3e3e',
        backgroundColor: '#fff',
        borderRadius: '5px',
        padding: '.5rem 1rem',
        marginBottom: '.5rem',
    },
    userPoints: {
        fontSize: '.6rem',
        color: 'black'
    },
    userPointsImg: {
        height: '1.5rem',
        width: '1.5rem',
        marginRight: '0.5rem',
    },
    button: {
        lineHeight: '2.5',
        marginTop: '.5rem',
        padding: '0',
        color: '#ffffff',
        backgroundColor: 'black',
        "&:hover": {
            fontSize: '1.1rem',
            backgroundColor: 'black',
        },
    },
    tab: {
        letterSpacing: '.2rem',
        color: '#fff',
        marginRight: '24px',
        cursor: 'pointer',
        "&:hover": {
            color: '#FEDC62',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            textDecoration: 'none',
        },
    },
}));
export function useSetUserInfo() {
    const dispatch = useDispatch();
    return async () => {
        await apiGetUserInfo()
            .then((res) => res.data)
            .then((resp) => {
                if (handleRespCode(resp) === false) {
                    return
                } else {
                    if (localStorage != null) {
                        localStorage.setItem('loginState', "true")
                        localStorage.setItem('userInfo', JSON.stringify(resp))
                    }
                    dispatch(setStateLogin())
                    dispatch(setUserAvatar(resp.pic_head_low))
                    dispatch(setUserName(resp.nickname))
                    dispatch(setUserDiamond(resp.total_diamond))
                }
            })
            .catch((error) => handleAPICall(error))
    }
}
export const clearUserInfo = async () => {
    return await apiUserLogout({ data: {} })
        .then((res) => res.data)
        .then((resp) => {
            handleRespCode(resp)
            // 不管server狀態，直接clear userInfo
            rmLocalStorage('loginState')
            rmLocalStorage('userInfo')
            rmLocalStorage(xCsrfTokenKey)
            rmLocalStorage(accessTokenKey)
            store.dispatch(setStateLogout())
            store.dispatch(setUserAvatar())
            store.dispatch(setUserName())
            store.dispatch(setUserDiamond())
            store.dispatch(setLoginDialogClose())
        })
        .catch((error) => handleAPICall(error))
}
const UserState = ({ newebpayOutage }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const storageLogin = JSON.parse(localStorage != null ? localStorage.getItem('loginState') : '{}');
    const storageUserInfo = JSON.parse(localStorage != null ? localStorage.getItem('userInfo') : '{}');
    const isLogin = useSelector(state => state.isStateLogin)
    const stateAvatar = useSelector(state => state.userAvatar)
    const stateUserName = useSelector(state => state.userName)
    const stateDiamond = useSelector(state => state.userDiamond)
    const stateDialogOpen = useSelector(state => state.isLoginDialogOpen)
    const dispatch = useDispatch();
    const setUserInfo = useSetUserInfo();
    const popoverAnchor = "avatarPosition";
    const hasLocalStorage = storageLogin !== null && storageUserInfo !== null
    useEffect(() => {
        const resp = JSON.parse(localStorage != null ? localStorage.getItem('userInfo') : '{}');
        if (hasLocalStorage === false) {
            clearUserInfo()
            setOpen(false)
            return
        } else {
            dispatch(setStateLogin())
            dispatch(setUserAvatar(resp.pic_head_low));
            dispatch(setUserName(resp.nickname));
            dispatch(setUserDiamond(resp.total_diamond));
        }
    }, [hasLocalStorage, dispatch]);
    const handleClick = (e) => {
        if (hasLocalStorage === false) {
            clearUserInfo()
            setOpen(false)
            return
        }
        setOpen(true)
        setUserInfo()
    };
    const handleOpenLoginPage = () => {
        dispatch(setLoginDialogOpen())
    }
    const handleCloseLoginPage = () => {
        dispatch(setLoginDialogClose())
    }
    const logouted = () => {
        clearUserInfo().then(() => {
            handleCloseLoginPage()
            setOpen(false)
        })
    }
    return (<>
        {isLogin ?
            <>
                <Avatar id={popoverAnchor} src={stateAvatar} className={classes.small} onClick={() => handleClick()} />
                <Popover
                    id={stateAvatar}
                    open={open}
                    onEnter={() => setUserInfo()}
                    onClose={() => setOpen(false)}
                    anchorEl={document.getElementById(popoverAnchor)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div className={classes.userInfo}>
                        <Avatar src={stateAvatar} className={classes.large} />
                        <div>
                            <div className={classes.userName}>
                                {stateUserName}
                            </div>
                            <div className={classes.userPoints}>
                                當前餘額：
                                <img
                                    className={classes.userPointsImg}
                                    src="https://web-cdn.halu.love/images/purchase/coin.png"
                                    alt="point_ic" />{'\xa0'}
                                {stateDiamond}
                            </div>
                        </div>
                        <Button
                            className={classes.button}
                            onClick={() => logouted()}
                            fullWidth
                            variant="outlined"
                        >
                            登出
                        </Button>
                    </div>
                </Popover>
            </>
            :
            <>
                <div
                    className={classes.tab}
                    onClick={() => handleOpenLoginPage()}
                >
                    登入
                </div>
                <Dialog open={stateDialogOpen} onBackdropClick={() => handleCloseLoginPage()} scroll="body">
                    <LoginPopup handleClose={() => handleCloseLoginPage()} newebpayOutage={newebpayOutage} />
                </Dialog>
            </>}
    </>)
};


export default UserState;
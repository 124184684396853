import React, { useState, useEffect } from 'react';
import SkeletonMap from '../../components/SkeletonMap'
import Carousel from '../../components/Carousel';
import {
    apiGetEventBanner,
    handleAPICall,
} from '../../service/api';
interface EventBannerProps {
    skeletonWidth?: number,
    skeletonHeight?: number,
    carouselMaxWidth: number
}
const EventBanner = ({ skeletonWidth, skeletonHeight, carouselMaxWidth }: EventBannerProps) => {
    const [isBannerLoad, setBannerIsLoad] = useState(true);
    const [imageArr, setImageArr] = useState(['']);
    const [titleArr, setTitleArr] = useState(['']);

    const getBanner = async () => {
        const urlArr = []
        const altArr = []
        const resp = await apiGetEventBanner()
            .then((res) => res.data)
            .then((res) => res.adv_list)
            .catch((error) => handleAPICall(error))
        if (resp === null) {
            setBannerIsLoad(true)
            return
        }
        for (let i = 0; i < resp.length; i++) {
            urlArr.push(resp[i].img_url);
            altArr.push(resp[i].title);
        }
        setImageArr(urlArr)
        setTitleArr(altArr)
        setBannerIsLoad(false)
    }
    useEffect(() => {
        getBanner()
    }, [])

    return (
        <div>
            {!isBannerLoad ?
                <Carousel images={imageArr} titles={titleArr} maxWidth={carouselMaxWidth} /> :
                <SkeletonMap
                    length={1}
                    width={skeletonWidth}
                    height={skeletonHeight}
                />}
        </div>
    )
}

export default EventBanner;
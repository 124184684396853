import React, { memo, useState } from 'react';
import { Grid, useEventCallback, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Lottie from 'react-lottie';
import { css } from '@emotion/css';
import animationData from './coin3.json'

const useStyles = makeStyles((theme) => ({
    priceCard: {
        position: 'relative',
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        borderRadius: '10px',
        boxShadow: ' 0 3px 3px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#ffffff',
        minWidth: '291px',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: '#F3FDFF',
        }
    },
    pointPrice: {
        display: 'flex',
        flexWrap: 'no-wrap',
        alignItems: 'center',
        fontWeight: '500',
        fontSize: '1.2rem',
        color: 'white',
    },
    price: {
        textAlign: 'right',
    },
}))

const containerCss = css`
  position: relative;
  padding: '10px';
  display: 'flex';
  flexDirection: 'row';
  minWidth: '291px';
  cursor: pointer;
  p {
    font-weight: 600;
  }
`;

const coinCss = css`
  &::before {
    content: '';
    padding-bottom: 100%;
    display: block;
  }
  position: relative;
`;

const coinContentCss = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #FEDC62;
`;

const coinImgCss = css`
  min-width: 48px;
  min-height: 48px;
  padding-top: 60%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('https://web-cdn.halu.love/images/purchase/halucoin.png');
`;


const defaultOptions = {
    loop: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

var thousandComma = function (number) {
    var num = number.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(num)) {
        num = num.replace(pattern, "$1,$2");
    }
    return num;
}
const Price = ({ coins, price, id, onClick }) => {
    const classes = useStyles();
    const [active, setActive] = useState(false);
    const enter = useEventCallback(() => setActive(true));
    const leave = useEventCallback(() => setActive(false));
    return (
        <div className={containerCss} onClick={onClick} role="button" id={id}>
            <div className={coinCss} onMouseEnter={enter} onMouseLeave={leave}>
                {active ? (
                  <img
                    src="https://web-cdn.halu.love/images/purchase/coin-border-selected.png"
                    alt="border"
                    className={css`
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                    `}
                  />
                ) : (
                  <img
                    src="https://web-cdn.halu.love/images/purchase/coin-border.png"
                    alt="border"
                    className={css`
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      opacity: 1;
                      &:hover {
                        opacity: 0;
                      }
                    `}
                  />
                )}
                <div className={coinContentCss}>
                  <div
                    style={{
                      display: !active ? 'none' : 'block',
                      maxHeight: '79px',
                    }}
                  >
                    <Lottie
                      options={defaultOptions}
                      width={79}
                      height={79}
                      isStopped={!active}
                    />
                  </div>

                  <div
                    className={coinImgCss}
                    style={{ display: !active ? 'block' : 'none' }}
                  />
                  <Typography align="center">{`${thousandComma(coins)}`}</Typography>
                </div>
            </div>
            <Typography align="center">{`NT$${thousandComma(price)}`}</Typography>
        </div>
    );
};

export default memo(Price);
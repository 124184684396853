import { Button } from "@material-ui/core";
interface SubmitBtnProps {
    onClick(): void
    disabled: boolean
    className?: string
    label: string
}
const SubmitButton = ({ onClick, disabled, className, label }: SubmitBtnProps) => {
    return (
        <Button
            type="submit"
            fullWidth
            onClick={onClick}
            disabled={disabled}
            className={className}
            variant="contained"
        >
            {label}
        </Button>)
}
export default SubmitButton;
import React, { memo, useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import SubmitButton from "./component/SubmitBtn";
import { validPhone, validPassword } from './isValid';
import {
    apiLoginPhone,
    apiModifyPhonePwd,
    handleAPICall,
    setLocalStorageEncrypt,
    accessTokenKey,
    xCsrfTokenKey
} from '../service/api';
import md5 from 'md5';
import { useSetUserInfo } from '../UserState';
import { useDispatch } from 'react-redux';
import { setErrorMsg, setSnackbarOpen } from '../actions';
import Done from '../components/Done';
import AreaCodeSelector from './component/AreaCodeSelector';
import PhoneNumberInput from './component/PhoneNumberInput';
import SMSCaptcha from './component/SMSCaptcha';
import ReCaptcha from './component/GoogleReCaptcha';
import { passwordLogin, smsLogin, resetPassword } from './Const';
import FormControl from '@material-ui/core/FormControl';
import PasswordInput from './component/PasswordInput';

const useStyles = makeStyles((theme) => ({
    fieldCSS: {
        zIndex: 0,
        marginTop: '.5rem',
    },
    reCaptcha: {
        padding: '1px',
        [theme.breakpoints.down('sm')]: {
            transform: 'scale(0.9)',
            transformOrigin: '0 0',
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'scale(0.77)',
            transformOrigin: '0 0',
        }
    },
    button: {
        border: 'none',
        backgroundColor: '#fff',
        "&:hover": { color: '#01579B' },
    },
    btnGroupCSS: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '.8rem',
        marginTop: '.5rem',
    },
    submitBtn: {
        border: 'none',
        lineHeight: '3',
        marginTop: '1rem',
        color: '#ffffff',
        backgroundColor: 'black',
        "&:hover": { color: '#ffffff', backgroundColor: 'black', },
    },
}));
interface PhoneLoginProps {
    type: 'passwordLogin' | 'smsLogin' | 'resetPassword'
    timesUp(): void
    setErrMsgPlaceholder(e: string): void
    smsTypeOnClick(): void
    resetPwdTypeOnClick(): void
    setIsWaitingLoginResp(isWaiting: Boolean): void
}
const PhoneLogin = ({
    type,
    timesUp,
    setErrMsgPlaceholder,
    smsTypeOnClick,
    resetPwdTypeOnClick,
    setIsWaitingLoginResp,
    ...props }: PhoneLoginProps) => {
    const classes = useStyles();
    const [area_code, setAreaCode] = useState('886');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPwd, setConfirmPwd] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [hasChanged, setHasChanged] = useState(false);
    const [isRobot, setIsRobot] = useState(true);
    const setUserInfo = useSetUserInfo();
    const dispatch = useDispatch();
    let enablePwd = false;
    let enableConfirmPwd = false;
    let enableCaptcha = false;
    let disalbeSMSCaptchaButton = true;
    let submitBtn = {
        disabled: false,
        label: '登入',
        onClick: () => console.log(''),
    }
    // handle change
    const clearCurrentState = () => {
        setPassword('')
        setShowPassword(false)
        setConfirmPwd('')
        setCaptcha('')
        setIsRobot(true)
    }
    const handleChangePhone = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setPhone(e.target.value);
    const handleChangeSMS = (e: string) => setCaptcha(e);
    const handleClearPwd = () => {
        setPassword('')
        setConfirmPwd('')
    }
    const handleResetPwdDone = () => {
        setHasChanged(false)
        clearCurrentState()
        timesUp()
    }
    useEffect(() => clearCurrentState(), [type])
    useEffect(() => {
        // helperText for phone number
        if (validPhone(area_code, phone) || phone === '') {
            setErrMsgPlaceholder('')
        } else {
            setErrMsgPlaceholder('無效的手機號碼')
        }
    }, [area_code, phone, setErrMsgPlaceholder])
    useEffect(() => {
        // helperText for reset password
        if (type !== resetPassword) {
            return
        } else if (password !== '' && !validPassword(password)) {
            setErrMsgPlaceholder('密碼長度必須大於8字元')
        } else if (confirmPwd !== '' && confirmPwd !== password) {
            setErrMsgPlaceholder('密碼不一致')
        } else {
            setErrMsgPlaceholder('')
        }
    }, [confirmPwd, password, setErrMsgPlaceholder, type])
    // handle submit
    const phoneLogin = async (pwd: string) => {
        setErrMsgPlaceholder('')
        setIsWaitingLoginResp(true)
        await apiLoginPhone({
            area_code,
            phone,
            pwd
        })
            .then((res) => {
                if (res.data.code === 0) {
                    // save in localStorage
                    setLocalStorageEncrypt(accessTokenKey, res.data.access_token)
                    setLocalStorageEncrypt(xCsrfTokenKey, res.headers[xCsrfTokenKey])
                }
                return res.data
            })
            .then((resp) => {
                if (resp.code === 0) {
                    setUserInfo()
                }
                else {
                    setIsWaitingLoginResp(false)
                    dispatch(setSnackbarOpen())
                    dispatch(setErrorMsg(`${resp.msg}`))
                    setErrMsgPlaceholder(`${resp.msg}`)
                }
            })
            .catch((error) => {
                setIsWaitingLoginResp(false)
                handleAPICall(error)
            })
    }
    const resetPasswordFunc = async () => {
        setErrMsgPlaceholder('')
        const pwd = md5(password)
        await apiModifyPhonePwd({
            area_code,
            phone,
            pwd,
            captcha,
        }).then((res) => res.data)
            .then((resp) => {
                if (resp.code === 0) {
                    setHasChanged(true)
                } else {
                    setCaptcha('')
                    dispatch(setErrorMsg(`${resp.msg}`))
                    setErrMsgPlaceholder(`${resp.msg}`)
                    dispatch(setSnackbarOpen())
                }
            })
            .catch((error) => handleAPICall(error))
    }
    switch (type) {
        case passwordLogin:
            const isValidPwdLogin =
                validPhone(area_code, phone)
                && password !== ''
                ;
            enablePwd = true
            enableConfirmPwd = false
            enableCaptcha = false
            submitBtn.disabled = !isValidPwdLogin
            submitBtn.label = '登入'
            submitBtn.onClick = () => phoneLogin(md5(password))
            break
        case smsLogin:
            const isValidSMSLogin =
                validPhone(area_code, phone)
                && captcha !== ''
                && !isRobot
                ;
            enablePwd = false
            enableConfirmPwd = false
            enableCaptcha = true
            disalbeSMSCaptchaButton = isRobot || !validPhone(area_code, phone)
            submitBtn.disabled = !isValidSMSLogin
            submitBtn.label = '登入'
            submitBtn.onClick = () => phoneLogin(captcha)
            break
        case resetPassword:
            const isValidResetPassword =
                password !== ''
                && validPassword(password)
                && confirmPwd === password
                && captcha !== ''
                && !isRobot
                ;
            enablePwd = true
            enableConfirmPwd = true
            enableCaptcha = true
            disalbeSMSCaptchaButton =
                password === ''
                || !validPassword(password)
                || confirmPwd !== password
                || isRobot
                ;
            submitBtn.disabled = !isValidResetPassword
            submitBtn.label = '確認送出'
            submitBtn.onClick = () => resetPasswordFunc()
            break
        default:
            break
    }
    // validation
    const isValidPasswordInput = () => {
        if (type === passwordLogin || password === '') {
            return true
        } else return validPassword(password)
    };
    return (<>
        {!hasChanged ?
            <form onSubmit={(e) => e.preventDefault()}>
                <AreaCodeSelector onChange={(e: any) => setAreaCode(e.value)} />
                <PhoneNumberInput
                    error={!validPhone(area_code, phone) && phone !== ''}
                    value={phone}
                    textFieldCSS={classes.fieldCSS}
                    onChange={handleChangePhone}
                />
                <PasswordInput
                    autoComplete='current-password'
                    enable={enablePwd}
                    showPassword={showPassword}
                    value={password}
                    error={!isValidPasswordInput()}
                    textFieldCSS={classes.fieldCSS}
                    label={type === passwordLogin ? '請輸入密碼' : '請輸入新密碼'}
                    onClear={handleClearPwd}
                    onClick={() => setShowPassword(!showPassword)}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <PasswordInput
                    autoComplete='new-password'
                    enable={enableConfirmPwd}
                    showPassword={showPassword}
                    value={confirmPwd}
                    error={confirmPwd !== '' && confirmPwd !== password}
                    textFieldCSS={classes.fieldCSS}
                    label='再次輸入新密碼'
                    onClear={handleClearPwd}
                    onClick={() => setShowPassword(!showPassword)}
                    onChange={(e) => setConfirmPwd(e.target.value)}
                />
                {enableCaptcha ?
                    <div className={classes.fieldCSS}>
                        <div className={classes.reCaptcha}>
                            <ReCaptcha
                                reCaptchaOnExpired={() => setIsRobot(true)}
                                reCaptchaOnChange={() => setIsRobot(false)}
                            />
                        </div>
                        <FormControl variant="outlined" fullWidth>
                            <SMSCaptcha
                                type={type}
                                area_code={area_code}
                                phone={phone}
                                disabled={disalbeSMSCaptchaButton}
                                onChange={handleChangeSMS}
                            />
                        </FormControl>
                    </div> :
                    <div className={classes.btnGroupCSS}>
                        <button className={classes.button}
                            onClick={smsTypeOnClick}
                        >
                            使用驗證碼登入
                        </button>
                        <button className={classes.button}
                            onClick={resetPwdTypeOnClick}
                        >
                            忘記密碼?
                        </button>
                    </div>
                }
                <SubmitButton
                    onClick={submitBtn.onClick}
                    disabled={submitBtn.disabled}
                    className={classes.submitBtn}
                    label={submitBtn.label}
                />
            </form>
            : <Done
                label={'已更新密碼，請重新登入'}
                timesUp={handleResetPwdDone}
            />
        }
    </>)
};

export default memo(PhoneLogin);
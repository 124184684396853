import ReCAPTCHA from "react-google-recaptcha";
export interface ReCaptchaProps {
    reCaptchaOnExpired(): void
    reCaptchaOnChange(): void
}
declare global {
    interface Window { _env_: any; }
}

window._env_ = window._env_ || {};
const ReCaptcha = ({ reCaptchaOnExpired, reCaptchaOnChange }: ReCaptchaProps) => {
    return (
        <ReCAPTCHA
            sitekey={window._env_.RECAPTCHA_SITE_KEY}
            type='image'
            size='normal'
            onExpired={reCaptchaOnExpired}
            onChange={reCaptchaOnChange}
        />
    )
}
export default ReCaptcha;
import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    back: {
        border: 'none',
        backgroundColor: 'white',
        width: '25px',
        height: '25px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    }
}))
interface BackIconProps {
    className?: string
    onClick(): void
}
const BackIcon = ({ className, onClick }: BackIconProps) => {
    const classes = useStyles()
    return (<>
        <button
            className={classes.back}
            onClick={onClick}
            style={{ backgroundImage: `url(https://web-cdn.halu.love/images/general/back.png)` }}
        />
    </>)
}
export default BackIcon
import React, { useState } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselCaption
} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
interface CarouselProps {
    images: string[],
    titles: string[],
    maxWidth: number,
}
const MockupCarousel = ({ images, titles, maxWidth }: CarouselProps) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const items = [];
    for (let i = 0; i < images.length; i++) {
        items.push({
            id: i,
            altText: titles[i],
            caption: ' ',
            src: images[i],
        })
    }
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }
    const slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.id}
            >
                <img
                    src={item.src}
                    alt={item.altText}
                    style={{
                        display: 'block',
                        margin: 'auto',
                        maxWidth: maxWidth,
                        width: '100%'
                    }}
                />
                <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
            </CarouselItem>
        );
    });

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={3000}
        >
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
    );
}

export default MockupCarousel;
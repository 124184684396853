import React from 'react';
import { Link } from '@material-ui/core';
import { CardProps } from './QRcodeCard';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    linkWrap: {
        margin: '16px',
        maxWidth: '240px',
    },
    linkImg: {
        height: '100%',
        width: '100%',
        maxWidth: '220px',
        minWidth: '220px',
    }
}));

const LinkBtn = ({ properties }: CardProps) => {
    const classes = useStyles();
    const { href, src, alt } = properties;
    return (
        <div className={classes.linkWrap}>
            <Link href={href} target="_blank">
                <img
                    src={src}
                    alt={alt}
                    className={classes.linkImg}
                />
            </Link>
        </div>
    )
};

export default LinkBtn;
import React, { useState, useEffect } from 'react';
import { apiGetEvnet } from '../../service/api';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    eventWrap: {
        color: '#707070',
        flexDirection: 'row',
        padding: '.2rem .5rem',
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '60%',
    },
    eventContent: {
        display: 'flex',
    },
    countDown: {
        color: '#cc0000',
    },
}))
const calculateTimeLeft = (endDate) => {
    let diff = +endDate - +new Date();
    if (diff < 0) {
        diff = 0;
    }
    const timeLeft = {
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((diff / 1000 / 60) % 60),
        seconds: Math.floor((diff / 1000) % 60)
    };
    return timeLeft;
}
const timeToDate = (t) => {
    // time format: 20201127000000
    return new Date(t.replace(
        /(\d\d\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)/, '$1-$2-$3T$4:$5:$6'
    ));

}
const EventCountDown = () => {
    const classes = useStyles();
    const [timeLefts, setTimeLefts] = useState([]);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        apiGetEvnet().then((res) => {
            const now = Date.now();
            const events = res.data.activity_list
                .filter(event => timeToDate(event.end_time) > now)
                .sort((a, b) => a.id < b.id ? 1 : -1)

            getCountdown(events);
            setEvents(events);
        });
    }, []);
    const getCountdown = (events) => {
        const endTimeArr = events.map(event => calculateTimeLeft(timeToDate(event.end_time)));
        setTimeLefts(endTimeArr);
    }
    const EventList = () => {
        return events
            .map((event, index) => {
                const {
                    activity_title: title,
                } = event;
                const timeLeft = timeLefts[index]
                return (
                    <div className={classes.eventWrap} key={title.toString()}>
                        <div className={classes.title} key={title}>
                            {title}
                        </div>
                        <div className={classes.eventContent} key={index}>
                            活動尚餘
                            <div className={classes.countDown}>
                                {timeLeft.days}
                            </div>
                            天
                        </div>
                    </div>
                );
            });
    };
    return (<><EventList /></>)
}

export default EventCountDown;

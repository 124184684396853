import { google } from './Const';
import { handleAPICall } from '../service/api';
import { useGoogleLogin } from '@react-oauth/google';

const GoogleLoginButton = ({ thirdPartyLogin, styleProps }) => {
    const onsuccess = (res) => {
        thirdPartyLogin(google, "auth-code", res.code)
    }

    const redirect_uri = window._env_.REDIRECT_URL
    const login = useGoogleLogin({
        onSuccess: tokenResponse => onsuccess(tokenResponse),
        onError: error => handleAPICall(error),
        redirect_uri: {redirect_uri},
        flow: 'auth-code',
      });


    return (
        <div className={styleProps} style={{ backgroundImage: `url(https://web-cdn.halu.love/images/login/Google_80X80.png)` }} onClick={login} />
    )
}

export default GoogleLoginButton
import React, { useState } from 'react';
import QRcodeCard from '../../components/QRcodeCard';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ios, android, apk } from '../Download/const';

const useStyles = makeStyles((theme) => ({
    btns: {
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '1rem',
    },
    btn: {
        border: '1px solid #f3f3f3',
    }
}));
interface SwitchBtnProps {
    type: string,
    onClick(): void
}
const SwitchBtn = ({ type, onClick }: SwitchBtnProps) => {
    const classes = useStyles();
    return (
        <Button className={classes.btn} onClick={onClick} >{type}</Button>
    )
}

const DownloadCard = () => {
    const [downloadType, setDownloadType] = useState(ios.label);
    const classes = useStyles();

    return (<>
        <Typography>立即加入</Typography>
        <br />
        <div className={classes.btns}>
            <SwitchBtn
                type={ios.label}
                onClick={() => setDownloadType(ios.label)}
            />
            <SwitchBtn
                type={android.label}
                onClick={() => setDownloadType(android.label)}
            />
            <SwitchBtn
                type={apk.label}
                onClick={() => setDownloadType(apk.label)}
            />
        </div>
        <QRcodeCard type={downloadType} label=''/>
    </>)
}

export default DownloadCard;
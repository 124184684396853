import React, {
    forwardRef,
    Fragment,
    memo,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { css } from '@emotion/css';
import {
    AppBar,
    Toolbar,
    Tab,
    Tabs,
    useMediaQuery,
    useEventCallback,
    MenuItem,
    MenuList,
    Popper,
    Grow,
    useTheme,
} from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import BurgerMenu from '../components/BurgerMenu';
import UserState from '../UserState';
import {
    version1PurchaseURL,
    newebpayOutageURL
} from '../service/api';
const useStyles = makeStyles((theme) => ({
    logo: {
        height: '2rem',
        width: '2rem',
    },
    spaceAround: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tab: {
        minWidth: '96px',
        letterSpacing: '.2rem',
        color: '#fff',
        "&:hover": {
            color: 'white',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            textDecoration: 'none',
        },
        '&::after': {
            content: '""',
            display: 'block',
            height: '1rem',
            borderRight: '1px solid white',
            position: 'absolute',
            right: '0',
        },
    },
    hash: {
        // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        padding: '6px 12px',
        fontWeight: '300',
        fontSize: '0.875rem',
        display: 'flex',
        justifyContent: 'center',
        alignitems: 'center',
        minWidth: '96px',
        letterSpacing: '0.2rem',
        color: '#fff',
        "&:hover": {
            color: 'white',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            textDecoration: 'none',
        },
        "&::after": {
            content: '"',
            height: '1rem',
            borderRight: '1px solid white',
            position: 'absolute',
            right: '-1px',
        }
    },
    noTabDesc: {
        display: 'inline',
        letterSpacing: '.0rem',
        color: 'white',
        padding: 5,
    },
}));

const indicatorCss = css`
  display: flex;
  justify-content: center;
  min-height: 8px;
  && {
    background-color: transparent;
  }
  & > span {
    z-index: 1;
    border-radius: 4px;
    max-width: 2rem;
    width: 100%;
    background-color: #FEDC62;
  }
`;

const trackCss = css`
  background-color: #000;
  position: absolute;
  left: 36px;
  right: 36px;
  height: 4px;
  border-radius: 2px;
  bottom: 2px;
`;

const Topbar = forwardRef(({ enableLoginAndPurchase, newebpayOutage, children, ToolbarProps = {}, ...props }, ref) => {
    const classes = useStyles();
    return (
        <AppBar color="primary" position="sticky" {...props} ref={ref} className={classes.container}>
            <Toolbar {...ToolbarProps}>{children}</Toolbar>
            {enableLoginAndPurchase ? <UserState newebpayOutage={newebpayOutage} /> : <></>}
        </AppBar>
    );
});

const Logo = () => {
    const classes = useStyles();
    return (
        <div>
            <img
                src={`${process.env.PUBLIC_URL}/logo.png`}
                alt="logo"
                className={classes.logo}
            />
        </div>
    )
}

const Appbar = ({ showTabs, enableLoginAndPurchase, newebpayOutage }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { pathname } = useLocation();
    const ref = useRef();
    const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const [isOpen, setIsOpen] = useState(false);
    const toggle = useEventCallback(() => setIsOpen((prev) => !prev));
    const close = useEventCallback(() => setIsOpen(false));
    const selectedMenuItemCss = useMemo(
        () => css`
          && {
            color: ${theme.palette.secondary.light};
          }
        `,
        [theme.palette.secondary.light]
    );
    useEffect(() => {
        if (desktop) close();
    }, [close, desktop]);

    return (
        <Topbar
            ref={ref}
            enableLoginAndPurchase={enableLoginAndPurchase}
            newebpayOutage={newebpayOutage}
        >
            {showTabs ? <div></div> :
                <div className={classes.container}>
                    <Logo />
                    <div className={classes.noTabDesc}>
                        Halu Live
                    </div>
                </div>
            }
            {showTabs && desktop && (<>
                <NavLink to="/" className={classes.logo}>
                    <Logo />
                </NavLink>
                <div >
                <Tabs
                    value={pathname}
                    TabIndicatorProps={{ children: <span/> }}
                    classes={{ indicator: indicatorCss }}
                    // className={classes.container}
                >
                    <Tab
                        component={NavLink}
                        className={classes.tab}
                        to="/"
                        value="/"
                        label="首頁"
                    />
                    <Tab
                        component={NavLink}
                        className={classes.tab}
                        to="/events"
                        value="/events"
                        label="活動"
                    />
                    {/* <Tab
                        component={NavLink}
                        className={classes.tab}
                        to="/gifts"
                        value="/gifts"
                        label="禮物"
                    /> */}
                    {enableLoginAndPurchase && !newebpayOutage
                        ? <Tab
                            component={NavLink}
                            className={classes.tab}
                            to="/purchase"
                            value="/purchase"
                            label="儲值"
                        />
                        : <a href={newebpayOutage ? newebpayOutageURL : version1PurchaseURL}
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: 'none' }}>
                            <Tab
                                label="儲值"
                                className={classes.tab}
                            />
                        </a>
                    }

                    <Tab
                        component={NavLink}
                        className={classes.tab}
                        to="/download"
                        value="/download"
                        label="下載"
                    />
                    <Tab
                        component={NavLink}
                        className={classes.tab}
                        to="/contactus"
                        value="/contactus"
                        label="聯絡我們"
                    />
                </Tabs>
                </div>
            </>)
            }
            <div
                className={css`
                  flex: 1;
                `}
            />
            {showTabs && !desktop && (
                <Fragment>
                    <BurgerMenu size="48px" onClick={toggle} open={isOpen} />
                    <Popper open={isOpen} anchorEl={ref.current} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <MenuList
                                    className={css`
                                    background: ${theme.palette.primary.main};
                                    width: 100vw;
                                    `}
                                >
                                    <MenuItem
                                        className={classes.hash}
                                        component={NavLink}
                                        alignitems="center"
                                        to="/"
                                        selected={'/' === pathname}
                                        classes={{
                                            selected: selectedMenuItemCss,
                                        }}
                                        onClick={close}
                                    >
                                        {'首頁'}
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.hash}
                                        component={NavLink}
                                        alignitems="center"
                                        to="/events"
                                        selected={'/events' === pathname}
                                        classes={{
                                            selected: selectedMenuItemCss,
                                        }}
                                        onClick={close}
                                    >
                                        {'活動'}
                                    </MenuItem>
                                    {/* <MenuItem
                                        className={classes.hash}
                                        component={NavLink}
                                        alignitems="center"
                                        to="/gifts"
                                        selected={'/gifts' === pathname}
                                        classes={{
                                            root: selectedMenuItemCss,
                                        }}
                                        onClick={close}
                                    >
                                        {'禮物'}
                                    </MenuItem> */}
                                    {enableLoginAndPurchase && !newebpayOutage
                                        ? <MenuItem
                                            className={classes.hash}
                                            component={NavLink}
                                            alignitems="center"
                                            to="/purchase?payment=newebpay"
                                            selected={'/purchase' === pathname}
                                            classes={{
                                                selected: selectedMenuItemCss,
                                            }}
                                            onClick={close}
                                        >
                                            {'儲值'}
                                        </MenuItem>
                                        : <a href={newebpayOutage ? newebpayOutageURL : version1PurchaseURL}
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{ textDecoration: 'none' }}>
                                            <MenuItem
                                                className={classes.hash}
                                                alignitems="center"
                                                classes={{
                                                    selected: selectedMenuItemCss,
                                                }}
                                                onClick={close}
                                            >
                                                {'儲值'}
                                            </MenuItem>
                                        </a>}
                                    <MenuItem
                                        className={classes.hash}
                                        component={NavLink}
                                        alignitems="center"
                                        to="/download"
                                        selected={'/download' === pathname}
                                        classes={{
                                            selected: selectedMenuItemCss,
                                        }}
                                        onClick={close}
                                    >
                                        {'下載'}
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.hash}
                                        component={NavLink}
                                        alignItems="center"
                                        to="/contactus"
                                        selected={'/contactus' === pathname}
                                        classes={{
                                          selected: selectedMenuItemCss,
                                        }}
                                        onClick={close}
                                    >
                                        {'聯絡我們'}
                                    </MenuItem>
                                </MenuList>
                            </Grow>
                        )}
                    </Popper>
                </Fragment>
            )}
        </Topbar>
    );
};

export default memo(Appbar);

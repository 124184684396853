import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
    root: {
        background: '#f3f3f3',
        borderRadius: '5px',
        flexGrow: 1,
        color: '#3e3e3e',
        display: 'flex',
        justifyContent: 'space-around',
        padding: '.5rem',
        alignItems: 'center',
    },
    avatar: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginRight: '.5rem',
    }
}))
const UserInfo = () => {
    const stateAvatar = useSelector(state => state.userAvatar)
    const stateUserName = useSelector(state => state.userName)
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div>儲值帳號</div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', }}>
                <Avatar
                    className={classes.avatar}
                    alt="avatar"
                    src={stateAvatar}
                />
                <div>{stateUserName}</div>
            </div>
        </div>
    )
};

export default UserInfo;
import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import { css } from '@emotion/css';

const labelCss = css`
  margin-bottom: 40px;
`;
const qrcodeCss = css`
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 15vw;
  position: relative;
`;

const textCss = css`
  && {
    font-size: 2rem;
    width: 100%;
    text-align: center;
  }
`;

const ContactUs = () => {
  return (
    <div
      className={css`
        flex: 1;
        justify-content: center;
        margin: 40px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 650px;
        & > div {
          max-width: 100%;
          overflow: hidden;
        }
      `}
    >
      <div className={labelCss}>
        <Typography className={textCss}>聯絡我們</Typography>
      </div>
      <div className={qrcodeCss}>
        <img
          src={`${process.env.PUBLIC_URL}/images/contact_us_border.png`}
          className={css`
            max-width: 286px;
            min-width: 200px;
            object-fit: scale-down;
          `}
          alt=""
        />
        <img
          src={`https://web-cdn.halu.love/images/general/halu-lineid.JPG`}
          alt=""
          className={css`
            position: absolute;
            width: 100%;
            object-fit: scale-down;
          `}
        />
      </div>
      <div>
        {/*
        <Typography id="phone" className={textCss}>客服電話：</Typography>
        <Typography id="company" className={textCss}>公司名稱：</Typography> */}
        {/* <Typography id="line" className={textCss}>@</Typography> */}
        <Typography id="line" className={textCss}>@437bohan</Typography>
        {/* <Typography id="phone" className={textCss}>客服電話：(02) 2740-7798</Typography> */}
        <Typography id="company" className={textCss}>公司名稱：瀨芙新創有限公司</Typography>

      </div>
    </div>
  );
};

export default memo(ContactUs);

import React from "react";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    overrides: {
        // 样式表的名字 ⚛️
        MuiOutlinedInput: {
            // 规则的名字
            input: {
                // 一些 CSS
                backgroundColor: '#fff'
            },
        }
    },
});

const useStyles = makeStyles((theme) => ({
    password: {
        cursor: 'pointer',
    }
}));
interface PwdInputProps {
    enable: boolean
    error?: boolean
    showPassword: boolean
    value: string
    textFieldCSS?: string
    label?: string
    autoComplete: string
    onClear(): void
    onClick(): void
    onChange(e: any): void
}

const PasswordInput = ({
    enable,
    error,
    showPassword,
    value,
    textFieldCSS,
    label,
    autoComplete,
    onClear,
    onClick,
    onChange }: PwdInputProps) => {
    const classes = useStyles();
    var isPassword = value !== '';

    return (<>
        {enable ?
            <ThemeProvider theme={theme}>
                <FormControl variant="outlined" fullWidth className={textFieldCSS}>
                    <InputLabel >{label}</InputLabel>
                    <OutlinedInput
                        id="password"
                        type={showPassword ? "text" : "password"}
                        value={value}
                        labelWidth={115}
                        error={error}
                        autoComplete={autoComplete}
                        onChange={onChange}
                        endAdornment={isPassword ?
                            <InputAdornment position="end" >
                                <img
                                    className={classes.password}
                                    onClick={onClear}
                                    src='https://web-cdn.halu.love/images/login/clear.svg'
                                    alt='clear'
                                />
                                <img
                                    className={classes.password}
                                    onClick={onClick}
                                    src={`https://web-cdn.halu.love/images/login/${showPassword ? 'visible' : 'hide'}.svg`}
                                    alt={showPassword ? 'visible' : 'hide'}
                                />
                            </InputAdornment> : <></>
                        }
                    />
                </FormControl>
            </ThemeProvider>: <></>}
    </>)
}
export default PasswordInput
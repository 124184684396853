import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from '@material-ui/core';
import { event, onlyForEvent } from './const';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 800,
        color: '#0A579B',
        borderRadius: '0px',
        boxShadow: 'none',
        borderBottom: '1px solid #707070'
    },
    image: {
        maxWidth: 100,
        minWidth: 80,
        maxHeight: 100,
        minHeight: 80,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    hasTag: {
        display: 'inline-block',
        background: '#0A579B',
        padding: '3px',
        color: '#fff',
        margin: '0 3rem',
    },
    noTag: {
        display: 'inline-block',
        background: '#fff',
        padding: '3px',
        color: '#fff',
        margin: '0 3rem',
        width: '62.41px'
    },
    price: {
        display: 'flex'
    },
    shell: {
        width: '40px',
        [theme.breakpoints.down('sm')]: {
            width: '25px'
        }
    },
    mobileCardWrap: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        justifyContent: 'space-between',
    },
    mobileHasTag: {
        background: '#0A579B',
        padding: '3px',
        color: '#fff',
        position: 'absolute',
        left: '16px',
        bottom: '-5px'
    },
    mobileNoTag: {
        display: 'inline',
        background: '#fff',
        padding: '3px',
        color: '#fff',
    },
    top: {
        position: 'fixed',
        bottom: '32px',
        right: '16px',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        background: '#f3f3f3',
        textAlign: 'center',
        lineHeight: '40px',
        cursor: 'pointer',
        zIndex: 20,
        boxShadow: '1px 1px 6px rgba(177, 177, 177, .6)',
        '&:hover': {
            background: '#CBEAF9',
            transition: 'all 0.3s ease-in-out',
        },
        [theme.breakpoints.down('sm')]: {
            bottom: '32px',
            right: '16px',
        }
    },
}))
type GiftItemProps = {
    pic_url: string,
    name: string,
    price: number,
    tag: string,
}
const GiftItem: React.FC<GiftItemProps> = ({ pic_url, name, price, tag }) => {
    const classes = useStyles();
    const desktop = useMediaQuery('(min-width:600px)');
    const hasTag = tag === event
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {!desktop ?
                    // mobile view
                    <Grid container alignItems="center" justify="space-between" className={classes.mobileCardWrap}>
                        <Grid item>
                            <div className={hasTag ? classes.mobileHasTag : classes.mobileNoTag}>
                                {hasTag ? onlyForEvent : ''}
                            </div>
                            <img className={classes.image} alt={name} src={pic_url} />
                        </Grid>
                        <Grid item style={{ textAlign: 'right', justifyContent: 'space-around' }}>
                            {name}
                            <div>
                                <img className={classes.shell} alt='shell' src={'https://web-cdn.halu.love/images/purchase/point_50x50.png'} />
                                {price}
                            </div>
                        </Grid>
                    </Grid>
                    :
                    // desktop view
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <div className={classes.image}>
                                <img className={classes.img} alt={name} src={pic_url} />
                            </div>
                        </Grid>
                        <Grid item xs={8} sm container style={{ alignItems: "center" }}>
                            <Grid item xs container spacing={1}>
                                <Grid item>
                                    <Typography className={hasTag ? classes.hasTag : classes.noTag} variant="subtitle2">
                                        {hasTag ? onlyForEvent : ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs container>
                                    <Typography variant="subtitle1">
                                        {name}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.price} style={{ alignItems: "center" }}>
                                <img className={classes.shell} src={'https://web-cdn.halu.love/images/purchase/point_50x50.png'} alt='shell_ic' />
                                <Typography variant="subtitle1">{price}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Paper>
        </div>
    )
}

export default GiftItem;
const initialState = {
    isStateLogin: false,
    userName: null,
    userAvatar: null,
    userDiamond: null,
    errorMsg: null,
    isSnackbarOpen: false,
    isLoginDialogOpen: false,
};

function allReducer(state = initialState, action) {
    switch (action.type) {
        case "SET_STATE_LOGIN":
            return Object.assign({}, state, { isStateLogin: true });
        case "SET_STATE_LOGOUT":
            return Object.assign({}, state, { isStateLogin: false });
        case "SET_USERNAME":
            return Object.assign({}, state, { userName: action.text });
        case "SET_AVATAR":
            return Object.assign({}, state, { userAvatar: action.text });
        case "SET_DIAMOND":
            return Object.assign({}, state, { userDiamond: action.text });
        case "SET_ERRORSMSG":
            return Object.assign({}, state, { errorMsg: action.text });
        case "SET_SNACKABR_OPEN":
            return Object.assign({}, state, { isSnackbarOpen: true });
        case "SET_SNACKBAR_CLOSE":
            return Object.assign({}, state, { isSnackbarOpen: false });
        case "SET_LOGIN_DIALOG_OPEN":
            return Object.assign({}, state, { isLoginDialogOpen: true });
        case "SET_LOGIN_DIALOG_CLOSE":
            return Object.assign({}, state, { isLoginDialogOpen: false });
        default:
            return state;
    }
}
export default allReducer;
import React, { useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogContentText,
} from "@material-ui/core";
import UserInfo from './UserInfo';
import { makeStyles } from '@material-ui/core/styles';
import NewebForm from './NewebForm';
import Card from './Card';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginDialogOpen } from '../../actions';
import SunpayForm from "./SunpayForm";

const useStyles = makeStyles((theme) => ({
    dialogWrap: {
        maxWidth: '500px',
        padding: '1rem',
    },
    logo: {
        height: '1.5rem',
        width: '1.5rem',
        marginRight: '0.5rem',
    },
    close: {
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '1rem',
        color: 'black',
    },
    context: {
        marginTop: '1rem',
    },
    form: {
        fontSize: '.6rem',
    },
}));
const SunpayDialogsEzpay = ({ payType, coins, price, id }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const isLogin = useSelector(state => state.isStateLogin)
    const cardOnClick = () => {
        if (isLogin === true) {
            setOpen(true)
        } else if (isLogin === false) {
            dispatch(setLoginDialogOpen())
        } else return
    }
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Card
                coins={coins}
                price={price}
                id={id}
                onClick={() => cardOnClick()}
            />
            <Dialog open={open} onBackdropClick={handleClose} scroll="body">
                <div className={classes.dialogWrap}>
                    <div className={classes.title}>
                        <div>
                            <img
                                src={`${process.env.PUBLIC_URL}/logo.png`}
                                alt="logo"
                                className={classes.logo}
                            />
                            <b>請確認購買資訊</b>
                        </div>
                        <div>
                            <div onClick={handleClose} className={classes.close}>
                                <img src={'https://web-cdn.halu.love/images/general/close_25x25.png'} alt="closeIcon" />
                            </div>
                        </div>
                    </div>
                    <DialogContent>
                        <DialogContentText>
                            您將使用以下帳號儲值購買，若確認資訊無誤後將引導至第三方網站支付款項，請安心前往結帳。
                        </DialogContentText>
                        <UserInfo />
                        <SunpayForm id={id} payType={payType} />
                        <DialogContentText className={classes.context}>
                            請您提高警覺: Halu Live 不會以任何分期付款失敗等名義要求您至 ATM 解除分期付款操作或提供信用卡等資料。
                        </DialogContentText>
                    </DialogContent>
                </div>
            </Dialog>
        </>
    );
}

export default SunpayDialogsEzpay;

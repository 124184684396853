import React, { useState, useRef, useEffect, } from "react";
import {
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {
    apiBuyRechargePaynow,
    payNowEpointAPI,
    apiPaymentInfoPaynow,
    apiModifyUserInfo,
    handleAPICall,
    handleRespCode,
} from '../../service/api';
import { validEmail, paynowValidName, validPhone } from './isValid';
import Card from './Card';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setLoginDialogOpen } from '../../actions';

const useStyles = makeStyles((theme) => ({
    logo: {
        height: '2rem',
        width: '2rem',
        marginRight: '1rem',
    },
    close: {
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    button: {
        lineHeight: '3',
        margin: '0',
        padding: '0',
        color: '#ffffff',
        backgroundColor: '#01579B',
        "&:hover": { color: '#ffffff', backgroundColor: '#01579B', },
    },
    dialogWrap: {
        maxWidth: '500px',
        margin: '10px',
    },
    context: {
        marginTop: '1rem',
    },
    form: {
        fontSize: '.6rem',
        marginBottom: '1rem',
    },
    root: {
        background: '#f3f3f3',
        borderRadius: '5px',
        flexGrow: 1,
        color: '#3e3e3e',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '1rem',
        margin: '.5rem 0'
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: '1rem',
    },
    textField: {
        width: '100%',
        display: 'flex',
        margin: '1rem 0rem'
    },
}));
const PaynowDialogs = ({ coins, price, id }) => {
    const classes = useStyles();
    const [originalName, setOriginalName] = useState('');
    const [originalEmail, setOriginalEmail] = useState('');
    const [originalPhone, setOriginalPhone] = useState('');
    const [userName, setUserName] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [checked, setChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const [helperTextName, setHelperTextName] = useState('');
    const stateAvatar = useSelector(state => state.userAvatar);
    const isLogin = useSelector(state => state.isStateLogin);
    const dispatch = useDispatch();
    const cardOnClick = () => {
        if (isLogin === true) {
            setOpen(true)
        } else {
            dispatch(setLoginDialogOpen())
        }
    }
    const handleChangeEmail = (e) => {
        setUserEmail(e.target.value);
    }
    const handleChangePhone = (e) => {
        setUserPhone(e.target.value);
    }
    const handleChangeName = (e) => {
        setUserName(e.target.value);
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleChangeCheckBox = () => {
        if (checked === true) {
            setChecked(false)
        } else setChecked(true)
    }
    function validateForm() {
        return validEmail(userEmail) && validPhone(userPhone) && paynowValidName(userName) && checked === true
    }
    useEffect(() => {
        paynowValidName(userName) ? setHelperTextName('') : setHelperTextName('不可為數字，長度限制 20 字元')
    }, [userName])
    const paynow_form = useRef(null);
    const paynow_form_webNo = useRef(null);
    const paynow_form_passCode = useRef(null);
    const paynow_form_receiverName = useRef(null);
    const paynow_form_receiverID = useRef(null);
    const paynow_form_receiverEmail = useRef(null);
    const paynow_form_receiverTel = useRef(null);
    const paynow_form_orderNo = useRef(null);
    const paynow_form_ECPlatform = useRef(null);
    const paynow_form_totalPrice = useRef(null);
    const paynow_form_orderInfo = useRef(null);
    const paynow_form_payType = useRef(null);
    const paynow_form_note1 = useRef(null);
    const paynow_form_atmRespost = useRef(null);
    const payNowFormSubmit = (data) => {
        paynow_form_webNo.current.value = data.webNo;
        paynow_form_passCode.current.value = data.PassCode;
        paynow_form_receiverName.current.value = data.ReceiverName;
        paynow_form_receiverID.current.value = data.ReceiverID;
        paynow_form_receiverEmail.current.value = data.ReceiverID;
        paynow_form_receiverTel.current.value = data.ReceiverTel;
        paynow_form_orderNo.current.value = data.OrderNo;
        paynow_form_ECPlatform.current.value = data.ECPlatform;
        paynow_form_totalPrice.current.value = data.TotalPrice;
        paynow_form_orderInfo.current.value = data.OrderInfo;
        paynow_form_payType.current.value = data.PayType;
        paynow_form_note1.current.value = data.Note1Account;
        paynow_form_atmRespost.current.value = data.AtmRespost;
        paynow_form.current.submit();
    }
    const setOrginalInfo = () => {
        if (isLogin !== true) {
            return
        } else {
            apiPaymentInfoPaynow()
                .then((res) => res.data)
                .then((resp) => {
                    if (handleRespCode(resp) === false) {
                        return
                    } else {
                        setOriginalPhone(resp.user_phone)
                        setOriginalName(resp.user_name)
                        setOriginalEmail(resp.user_email)
                        setUserPhone(resp.user_phone)
                        setUserName(resp.user_name)
                        setUserEmail(resp.user_email)
                    }
                })
                .catch((error) => handleAPICall(error))
        }
    }

    const isUserInfoChanged = () => {
        return userPhone !== originalPhone || userName !== originalName || userEmail !== originalEmail
    }
    const modifyUserInfo = async () => {
        return await apiModifyUserInfo({
            "user_name": userName,
            "user_email": userEmail,
            "user_phone": userPhone,
        })
            .then((res) => res.data)
            .then((resp) => {
                return handleRespCode(resp)
            })
        // we don't handle error because modifyUserInfo is not the main purpose of purchasing
    };
    const onBuySubmit = (recharge_id) => {
        const paynowBuyAPIResp = async () => {
            return await apiBuyRechargePaynow({
                recharge_id: recharge_id,
                pay_type: "01",
            })
                .then((res) => {
                    if (handleRespCode(res.data) === false) {
                        return undefined
                    } else {
                        return res.data
                    }
                })
        }
        const hasChanged = isUserInfoChanged();
        if (hasChanged === true) {
            modifyUserInfo()
                .then((res) => {
                    if (res !== true) {
                        return
                    }
                    return paynowBuyAPIResp()
                })
                .then((res) => {
                    if (res === undefined) {
                        return
                    }
                    return payNowFormSubmit(res)
                })
                .catch((error) => handleAPICall(error))
        } else {
            paynowBuyAPIResp()
                .then((resp) => {
                    if (resp !== undefined) {
                        payNowFormSubmit(resp)
                    }
                })
                .catch((error) => handleAPICall(error))
        }
    };
    return (
        <>
            <Card
                coins={coins}
                price={price}
                id={id}
                onClick={() => cardOnClick()}
            />
            <Dialog open={open} onBackdropClick={() => setOpen(false)} onEnter={() => setOrginalInfo()} scroll="body">
                <div className={classes.dialogWrap}>
                    <DialogTitle>
                        <div className={classes.title}>
                            <div>
                                <img
                                    src={'https://web-cdn.halu.love/images/general/newLOGO192.png'}
                                    alt="logo"
                                    className={classes.logo}
                                />
                                <b>請確認購買資訊</b>
                            </div>
                            <div>
                                <div onClick={handleClose} className={classes.close}>
                                    <img src={'https://web-cdn.halu.love/images/general/close_25x25.png'} alt="closeIcon" />
                                </div>
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            您將使用以下帳號儲值購買，若確認資訊無誤後將引導至第三方網站支付款項，請安心前往結帳。
                        </DialogContentText>
                        <Typography variant="body1" className={classes.context}>
                            儲值帳號
                        </Typography>
                        <div className={classes.root}>
                            <Avatar className={classes.avatar} alt="avatar" src={stateAvatar} />
                            <TextField
                                required
                                error={!paynowValidName(userName)}
                                margin="dense"
                                id="name"
                                label="購買人姓名"
                                type="name"
                                placeholder="請輸入您的真實姓名"
                                value={userName}
                                fullWidth
                                helperText={helperTextName}
                                variant="outlined"
                                onChange={handleChangeName}
                            />
                        </div>
                        <form className={classes.form} >
                            <div className={classes.textField}>
                                <TextField
                                    required
                                    error={!validEmail(userEmail)}
                                    margin="dense"
                                    id="email"
                                    label="購買人Email"
                                    type="email"
                                    placeholder="請輸入您的Email"
                                    value={userEmail}
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChangeEmail}
                                />
                            </div>
                            <div className={classes.textField}>
                                <TextField
                                    required
                                    error={!validPhone(userPhone)}
                                    margin="dense"
                                    id="phone"
                                    label="購買人電話"
                                    type="phone"
                                    placeholder="請輸入您的電話"
                                    value={userPhone}
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChangePhone}
                                />
                            </div>
                        </form>
                        <form id="paynow_form" ref={paynow_form} name='paynow' method='post' action={payNowEpointAPI}>
                            <input type='hidden' ref={paynow_form_webNo} id="webNo" name='webNo' value='' />
                            <input type='hidden' ref={paynow_form_passCode} id="PassCode" name='PassCode' value='' />
                            <input type='hidden' ref={paynow_form_receiverName} id="ReceiverName" name='ReceiverName' value='' />
                            <input type='hidden' ref={paynow_form_receiverID} id="ReceiverID" name='ReceiverID' value='' />
                            <input type='hidden' ref={paynow_form_receiverEmail} id="ReceiverEmail" name='ReceiverEmail' value='' />
                            <input type='hidden' ref={paynow_form_receiverTel} id="ReceiverTel" name='ReceiverTel' value='' />
                            <input type='hidden' ref={paynow_form_orderNo} id="OrderNo" name='OrderNo' value='' />
                            <input type='hidden' ref={paynow_form_ECPlatform} id="ECPlatform" name='ECPlatform' value='' />
                            <input type='hidden' ref={paynow_form_totalPrice} id="TotalPrice" name='TotalPrice' value='' />
                            <input type='hidden' ref={paynow_form_orderInfo} id="OrderInfo" name='OrderInfo' value='' />
                            <input type='hidden' ref={paynow_form_payType} id="PayType" name='PayType' value='' />
                            <input type='hidden' ref={paynow_form_note1} id="Note1" name='Note1' value='' />
                            <input type='hidden' ref={paynow_form_atmRespost} id="AtmRespost" name='AtmRespost' value='' />
                        </form>
                        <DialogContentText className={classes.context}>
                            <Checkbox
                                checked={checked}
                                onChange={() => handleChangeCheckBox()}
                                color="default"
                            />
                            確認付款資訊無誤
                            <br />
                        </DialogContentText>
                        <DialogActions>
                            <Button
                                onClick={() => onBuySubmit(id)}
                                fullWidth
                                className={classes.button}
                                variant="contained"
                                disabled={!validateForm()}
                            >
                                前往付款
                            </Button>
                        </DialogActions>
                        <DialogContentText className={classes.context}>
                            請您提高警覺: Halu Live 不會以任何分期付款失敗等名義要求您至 ATM 解除分期付款操作或提供信用卡等資料。
                        </DialogContentText>
                    </DialogContent>
                </div>
            </Dialog>
        </>
    );
}

export default PaynowDialogs;

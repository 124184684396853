import React from 'react';
import { Link } from '@material-ui/core';
import { CardProps } from './QRcodeCard';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    qrCodeWrap: {
        margin: '0px 0 15px 0',
        borderRadius: '16px',
        background: 'linear-gradient(to right, #FEDC62, #b77ef2)',
        padding: '4px'
    },
    qrCodeImg: {
        borderRadius: '16px',
        display: 'block',
        width: '100%',
        height: '100%',
        minWidth: '180px',
        minHeight: '180px',
        boxSizing: 'border-box',
        position: 'relative',
        boxShadow: '1px 1px 6px rgba(177, 177, 177, .6)',
        '&:hover': {
            boxShadow: '1px 1px 6px rgba(0, 87, 155, .6)',
        },
    },
}));

const QRcode = ({ properties }: CardProps) => {
    const classes = useStyles();
    const { href, src, alt } = properties;
    return (
        <div className={classes.qrCodeWrap}>
            <Link href={href} target="_blank">
                <img
                    src={src}
                    alt={alt}
                    className={classes.qrCodeImg}
                />
            </Link>
        </div>
    )
};

export default QRcode;
import React, { memo, useEffect, useState, Fragment } from 'react';
import { css } from '@emotion/css';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Events from './Events';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';

// const HomeCss = css`
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
// `;

const HomeCss = css`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Wrap = css`
    display: flex;
    justify-content: center;
`;

const Banner = css`
    //'max-width' depend on the img's width
    max-width: 1200px;
    width: 100vw;
    @media (max-width: 870px) {
        height: 100%;
    }
`;

const ContainerCss = css`
    background: #ffffff;
    padding: 0 3rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 1;
    width: 100%;

    @media (max-width: 870px) {
        flex-direction: column;
        padding: 1rem 0;
    }
`;

const Content = css`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    & > div{
        animation: change 2s ;
    }
    @keyframes change{
        0%{ opacity: 0;}
        100%{ opacity: 100;}
    }
`;

const LinesCss = css`
    font-size: 0.85rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > p{
    margin: 0;
    padding: 0;
    }
`;

const LineQRcodeCss = css`
    width: 5rem;
    height: 5rem;
    filter: opacity(100%);
    @media (min-width: 870px) {
        filter: opacity(50%);
        &:hover{
        filter: opacity(100%);
        animation: change .5s ;
        }
    }
`;

const logoCss = css`
  width: 20%;
  min-width: 216px;
  max-width: 390px;
  object-fit: scale-down;
  object-position: 50% 50%;
`;

const titleCss = css`
  margin-top: 2em;
  width: 7.8%;
  min-width: 120px;
  max-width: 151px;
`;
const subTitleCss = css`
  margin-top: 2.5em;
  width: 21.3%;
  min-width: 220px;
  max-width: 409px;
  object-fit: scale-down;
`;
const textCss = css`
  margin-top: 10px;
  font-size: 1.25rem;

  @media (min-width: 375px) {
    font-size: 1rem;
  }

  @media (min-width: 1366px) {
    font-size: 1.5rem;
  }
`;


const useStyles = makeStyles((theme) => ({
    container: {
        background: '#ffffff',
        padding: '1rem 3rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        zIndex: '1',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            zIndex: '0',
            padding: '1rem',
        },
    },
    topWrap: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 3rem',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    topWrapHalf: {
        width: '50%',
        padding: '1.4rem',
        margin: '.5rem 1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '0rem',
        },
    },
    event: {
        borderRadius: '10px',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#ffffff',
        maxWidth: '450px',
        maxHeight: '200px',
        minWidth: '400px',
        minHeight: '144px',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            width: '100%',
            minWidth: '300px',
        },
    },
    eventTitle: {
        "& a": { color: '#0060B2', },
        "& a:hover": { textDecoration: 'none', },
        height: '36px',
        lineHeight: '36px',
        borderRadius: '10px 10px 0 0',
        backgroundColor: '#ADEAFA',
        textAlign: 'center',
        justifyContent: 'center',
        fontWeight: '500',
    },
    eventWrap: {
        width: '100%',
        cursor: 'pointer',
        "& a:hover": { textDecoration: 'none', },
        [theme.breakpoints.down('sm')]: {
            height: '144px',
        },
    },
    lables: {
        height: '100%',
        maxWidth: '450px',
        minWidth: '400px',
        color: '#ffffff',
        textAlign: 'center',
        fontSize: '.85rem',
        fontWeight: '500',
        lineHeight: '40px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginLeft: '0rem',
            minWidth: '300px',
        },
    },
    lable_1: {
        borderColor: 'transparent transparent #1DB0D6 transparent',
        borderStyle: ' solid solid solid solid',
        borderWidth: '0px 20px 40px 0px',
        height: '30px',
        padding: '0 10px',
        margin: '10px 0',
    },
    lable_2: {
        borderColor: 'transparent transparent #00AA95 transparent',
        borderStyle: ' solid solid solid solid',
        borderWidth: '0px 20px 40px 0px',
        height: '40px',
        padding: '0 10px',
        margin: '10px 0',
    },
    lable_3: {
        borderColor: 'transparent transparent #55A000 transparent',
        borderStyle: ' solid solid solid solid',
        borderWidth: '0px 20px 40px 0px',
        height: '40px',
        padding: '0 10px',
        margin: '10px 0',
    },
    mockupCss: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '2rem',
        },
    },
    downloadBtn: {
        width: '180px',
        height: '40px',
        backgroundColor: '#ffffff',
        border: '1px #0060B2 solid',
        color: '#0060B2',
        fontWeight: '900',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        textDecoration: 'none',
        cursor: 'pointer',

        "&:hover": {
            background: '#0060B2',
            transition: '.3s',
            color: '#ffffff',
            border: '1px solid #039be5',
            textDecoration: 'none',
        }
    },
    infoContainer: {
        background: 'white',
        borderLeft: '1px solid #c5c5c5',
        zIdex: '2',
        width: '50%',
        padding: '1.4rem 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '0rem',
        },
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 2rem 2rem 2rem',
        backgroundColor: 'white',
        [theme.breakpoints.down('sm')]: {
            margin: '0 0 1.5rem 2rem',
        },
    },
    h2: {
        color: '#039be5',
        fontSize: '1.2rem',
    },
    mockupImg: {
        [theme.breakpoints.down('sm')]: {
            width: '250px',
        },
        minWidth: '250px',
        height: 'auto',
    }
}));

const contactUsLines = [
    "LINE 客服：@118VEVXZ",
    "客服電話：2740-0113",
    "（點擊QRcode）LINE 客服連結"
];
const aboutUsLines = [
    "HALU 是一個以分享內容爲核心的直播社交應用，允許用戶廣播實時視頻流和實時內容。HALU 是一個培育網紅與網路藝人的多元行銷公司，發掘、打造極具特色與發展潛力的對象。也透過系統規劃、專業設計的線上活動。如電影、出版、電視等等異業結盟方式，培養素人成為旗下網路藝人；為直播平台、自媒體新聞的主流。HALU 直播擁有時下最多的帥哥、正妹、多才多藝素人的平台，任何人都可以秀出自己的生活點滴、興趣、才藝，HALU 將成爲你唯一最好用的生活實錄平台，今天就讓50萬人愛上妳/你吧！"
];

function AppInfo(props) {
    const lines = [...props.lines]
    return (
        <div className={Content}>
            <Link
                rel="noopener"
                href={props.href}
                target="_blank"
            >
                <img src={props.imgURL} alt={props.alt} className={props.imgClassName} />
            </Link>
            <div className={LinesCss}>
                {lines.map((text) => { return <p key={text}>{text}<br /></p> })}
            </div>
        </div>
    )
}
const srcSetImg = {
    banner: {
        webp: 'https://web-cdn.halu.love/images/home/banner.webp',
        jpg: 'https://web-cdn.halu.love/images/home/banner.jpg',
    },
    mockup: {
        webp: 'https://web-cdn.halu.love/images/download/resizeMockup3.webp',
        png: 'https://web-cdn.halu.love/images/download/resizeMockup3.png',
    },
}
const Home = () => {
    const classes = useStyles();
    const isDeskTop = useMediaQuery('(min-width:375px)');
    //Parallax Scrolling
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        function handleScroll() {
            setOffset(window.pageYOffset);
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [offset]);


    return (
        <div id="home-container" className={HomeCss}>
          <img
            className={logoCss}
            src={`${process.env.PUBLIC_URL}/logo-main.png`}
            alt="logo"
          />
          <img
            className={titleCss}
            src={`${process.env.PUBLIC_URL}/title.png`}
            alt="title"
          />
          {/* <img
            className={subTitleCss}
            src={`${process.env.PUBLIC_URL}/subtitle.png`}
            alt="subtitle"
          /> */}
          {!isDeskTop ? (
            <Fragment>
              <div className={textCss}>當時光流轉，每個時刻都盼與你同行，</div>
              <div className={textCss}>一起分享喜怒哀樂。</div>
              <div className={textCss}>我們相伴相隨，彷彿星河滄海，</div>
              <div className={textCss}>共同描繪一幅美麗的友情風景。</div>
              <div className={textCss}>在這裡，我們可以共同探索無盡的可能，</div>
              <div className={textCss}>彼此照亮前行的路途。</div>
              <div className={textCss}>歡迎來到 HALU！與世界一起分享快樂。</div>
            </Fragment>
          ) : (
              <>
                <div className={textCss}> </div>
                <div className={textCss}>當時光流轉，每個時刻都盼與你同行，</div>
                <div className={textCss}>一起分享喜怒哀樂。</div>
                <div className={textCss}>我們相伴相隨，彷彿星河滄海，</div>
                <div className={textCss}>共同描繪一幅美麗的友情風景。</div>
                <div className={textCss}>在這裡，我們可以共同探索無盡的可能，</div>
                <div className={textCss}>彼此照亮前行的路途。</div>
                <div className={textCss}>歡迎來到 HALU！與世界分享快樂的互動交友平台。</div>
              </>
            )}
        </div>
    );
        // <div className={HomeCss}>
        //     {/* <div className={Wrap}>
        //         <picture style={{ transform: `translateY(${offset * 0.5}px)` }} >
        //             <source srcSet={`${srcSetImg.banner.webp} 1x`} type="image/webp" />
        //             <img
        //                 alt="banner"
        //                 className={Banner}
        //                 src={srcSetImg.banner.jpg}
        //             />
        //         </picture>
        //     </div> */}
        // </div>
};

export default memo(Home);

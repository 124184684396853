export function setStateLogin() {
    return {
        type: "SET_STATE_LOGIN",
    }
}
export function setStateLogout() {
    return {
        type: "SET_STATE_LOGOUT",
    }
}
export function setUserName(text) {
    return {
        type: "SET_USERNAME",
        text,
    };
}
export function setUserAvatar(text) {
    return {
        type: "SET_AVATAR",
        text,
    }
}
export function setUserDiamond(text) {
    return {
        type: "SET_DIAMOND",
        text,
    }
}
export function setErrorMsg(text) {
    return {
        type: "SET_ERRORSMSG",
        text,
    }
}
export function setSnackbarOpen() {
    return {
        type: "SET_SNACKABR_OPEN",
    }
}
export function setSnackbarClose() {
    return {
        type: "SET_SNACKBAR_CLOSE",
    }
}
export function setLoginDialogOpen() {
    return {
        type: "SET_LOGIN_DIALOG_OPEN",
    }
}
export function setLoginDialogClose() {
    return {
        type: "SET_LOGIN_DIALOG_CLOSE",
    }
}
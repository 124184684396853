import React from 'react';
import { memo } from 'react';
import { ios, android, apk } from '../pages/Download/const';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import QRcode from './QRcode';
import LinkBtn from './LinkBtn';

const useStyles = makeStyles((theme) => ({
    labelCss: {
        marginBottom: '12px'
    },
    cardWrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '270px',
        margin: '.5rem',
        '&:hover': {
            filter: 'none'
        },
        filter: 'opacity(0.5)',
        [theme.breakpoints.down('sm')]: {
            filter: 'none',
            maxWidth: '65vw'
        },
    }
}));

export interface CardProps {
    properties: {
        href: string,
        src: string,
        alt: string
    },
    className?: string
}

const QRcodeCard = ({ type, label }: { type: string, label: string }) => {
    const classes = useStyles();
    let qrcodeProps: CardProps['properties'];
    let downloadLinkProps: CardProps['properties'];
    switch (type) {
        case ios.label:
            qrcodeProps = ios.qrCodeProps;
            downloadLinkProps = ios.downloadLinkProps;
            break
        case android.label:
            qrcodeProps = android.qrCodeProps;
            downloadLinkProps = android.downloadLinkProps;
            break
        case apk.label:
            qrcodeProps = apk.qrCodeProps;
            downloadLinkProps = apk.downloadLinkProps;
            break
        default:
            qrcodeProps = apk.qrCodeProps;
            downloadLinkProps = apk.downloadLinkProps;
    }
    return (
        <div className={classes.cardWrap}>
            {label ? <div className={classes.labelCss}>
                <Typography>{label}</Typography>
            </div>:<div></div>}
            <QRcode properties={qrcodeProps} />
            <LinkBtn properties={downloadLinkProps} />
        </div >
    )
};

export default memo(QRcodeCard);
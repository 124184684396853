import React, { memo } from 'react';
import QRcodeCard from '../../components/QRcodeCard';
import { css } from '@emotion/css';
import {
    Grid,
    Container,
    useMediaQuery,
} from '@material-ui/core';
import Carousel from '../../components/Carousel';
import { ios, android, apk } from './const';

const mockupGroupCss = css`
    top: 5rem;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0rem 1rem 1rem 1rem;

    @keyframes change{
      0%{ opacity: 0;}
      100%{ opacity: 100;}
    }

    @media only screen and (max-width: 870px) {
        display: none;
    }
    `;

const mockupCss = css`
    height: 100%;
    margin: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation-name: myAnimate;
    animation-duration: .15s;
    animation-iteration-count: 5;
    @keyframes myAnimate{
        0% {transform: rotate(0deg);}
        25% {transform: rotate(4deg);}
        50%{transform: rotate(-4deg);}
        100% {transform: rotate(4deg);}
    }
`;

const cardGroupCss = css`
        flex: 1;
        top: 2rem;
        left: 0;
        right: 0;
        width: 100%;
        display: grid;
        align-content: center;
        padding-top: 8rem;
        padding-bottom: 2.5rem;
        grid-gap: auto;
        justify-items: center;
        grid-template-columns: repeat(3, 1fr);

        @media (max-width: 870px) {
            grid-template-columns: repeat(1, 1fr);
            top: 10rem;
        }
`;

const mkImgCss = css`
    max-height: 290px;
`;
const imageArr = [
    'https://web-cdn.halu.love/images/download/resizeMockup1.png',
    'https://web-cdn.halu.love/images/download/resizeMockup2.png',
    'https://web-cdn.halu.love/images/download/resizeMockup3.png',
]
const titleArr = [
    'AppMockup1',
    'AppMockup2',
    'AppMockup3',
]

const Download = () => {
    return (
        <div>
            <Container>
                <div className={cardGroupCss}>
                    <QRcodeCard type={ios.label} label={ios.label} />
                    <QRcodeCard type={android.label} label={android.label} />
                    <QRcodeCard type={apk.label} label={apk.label}/>
                </div>
            </Container>
        </div>
    );
};

export default memo(Download);

export const ios = {
    label: 'iOS',
    qrCodeProps: {
        href: 'https://apps.apple.com/tw/app/halu-love/id1537543888',
        alt: 'APPDownloadLink',
        src: `https://web-cdn.halu.love/images/download/ios_qrcode.png`
    },
    downloadLinkProps: {
        src: 'https://web-cdn.halu.love/images/download/app_store.png',
        alt: 'APPDownloadLink',
        href: 'https://apps.apple.com/tw/app/halu-love/id1537543888'
    }
}
export const android = {
    label: 'Android',
    qrCodeProps: {
        href: 'https://play.google.com/store/apps/details?id=com.love.halu',
        alt: 'APPDownloadLink',
        src: `https://web-cdn.halu.love/images/download/android_qrcode.png`
    },
    downloadLinkProps: {
        src: 'https://web-cdn.halu.love/images/download/google_play.png',
        alt: 'APPDownloadLink',
        href: 'https://play.google.com/store/apps/details?id=com.love.halu'
    }
};
export const apk = {
    label: 'APK',
    qrCodeProps: {
        href: 'https://web-cdn.halu.love/aab/halu.aab',
        alt: 'APPDownloadLink',
        src: `https://web-cdn.halu.love/images/download/halu_aab_qrcode.png`
    },
    downloadLinkProps: {
        src: 'https://web-cdn.halu.love/images/download/apk.png',
        alt: 'APPDownloadLink',
        href: 'https://web-cdn.halu.love/aab/halu.aab'
    }
};
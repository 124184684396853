import React, { useState, useRef, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { Button, } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import {
    apiBuyRechargeSunpay,
    apiPaymentGetLovecodeInfo,
    sunpayEpointAPI,
    handleAPICall,
    handleRespCode,
} from '../../service/api';
import {
    useLoveCode,
    email,
    personalInvoice,
    businessInvoice,
    CarrierTypeMember,
    CarrierTypePhone,
    CarrierTypeCDC
} from './Const';
import { isUbnError, isNameError, isEmailValid, isValidPhoneCarrier, isValidCDCCarrier } from './isValid';

const useStyles = makeStyles((theme) => ({
    form: {
        fontSize: '.6rem',
    },
    text: {
        padding: '2px',
        color: 'black',
    },
    textInputColor: {
        color: 'black'
    },
    loveCode: {
        padding: '2px',
        color: '#007bff',
    },
    button: {
        lineHeight: '3',
        marginTop: '1rem',
        border: 'none',
        color: '#ffffff',
        backgroundColor: 'black',
        "&:hover": { color: '#ffffff', backgroundColor: 'black', },
    },
    placeholder: {
        color: '#cc0000',
    },
    helperText: {
        color: '#cc0000',
        height: '50px',
    },
}));
const SunpayForm = ({ id, payType, ...props }) => {
    const classes = useStyles();
    const carrierTypeDefaultValue = 1
    const [selectedValue, setSelectedValue] = useState(useLoveCode);
    const [selectedCarrierTypeValue, setCarrierTypeValue] = useState(carrierTypeDefaultValue);
    const [carrier, setCarrier] = useState('');
    const [user_email, setUserMail] = useState('');
    const [buyer_ubn, setBuyerUbn] = useState('');
    const [buyer_name, setBuyerName] = useState('');
    const [use_love_code, setUseLoveCode] = useState(selectedValue === useLoveCode);
    const [inputDisable, setInputDisable] = useState(true);
    const [isEmailRequired, setEmailRequired] = useState(false);
    const [helperTextUbn, setHelperTextUbn] = useState('')
    const [helperTextName, setHelperTextName] = useState('')
    const [loveCodeInfo, setLoveCodeInfo] = useState('');
    const [phoneCarrierError, setPhoneCarrierError] = useState(null);
    const [CDCCarrierError, setCDCCarrierError] = useState(null);
    const [phoneCarrierHelperText, setPhoneCarrierHelperText] = useState(null);
    const [CDCCarrierHelperText, setCDCCarrierHelperText] = useState(null);

    const handleChangeRadio = (e) => {
        const target = e.target.value
        if (target === useLoveCode) {
            setSelectedValue(e.target.value)

            setHelperTextUbn('')
            setHelperTextName('')
            setUseLoveCode(true)
            setInputDisable(true)
            setUserMail('')
            setBuyerName('');
            setBuyerUbn('');
            setEmailRequired(false)
            setCarrierTypeValue(carrierTypeDefaultValue)
            setCarrier('')
            setPhoneCarrierHelperText('')
            setCDCCarrierHelperText('')
        } else if (target === personalInvoice) {
            setSelectedValue(e.target.value)

            setHelperTextUbn('')
            setHelperTextName('')
            setUseLoveCode(false)
            setInputDisable(false)
            setBuyerName('');
            setBuyerUbn('');
            setEmailRequired(true)
            setCarrierTypeValue(carrierTypeDefaultValue)
            setCarrier('')
            setPhoneCarrierHelperText('')
            setCDCCarrierHelperText('')
        } else if (target === businessInvoice) {
            setSelectedValue(e.target.value)

            setUseLoveCode(false)
            setInputDisable(false)
            setEmailRequired(true)
            setCarrierTypeValue(carrierTypeDefaultValue)
            setCarrier('')
            setPhoneCarrierHelperText('')
            setCDCCarrierHelperText('')
        }
    };
    const handleChangeCarrierType = (e) => {
        setCarrierTypeValue(parseInt(e.target.value))
        setCarrier('')
        setPhoneCarrierHelperText('')
        setCDCCarrierHelperText('')
    }
    useEffect(() => {
        isUbnError(buyer_ubn, buyer_name) ? setHelperTextUbn('買受人統一編號，純數字') : setHelperTextUbn('')
        if (!isNameError(buyer_ubn, buyer_name)) {
            setHelperTextName('')
        } else {
            if (buyer_name.length < 2 || buyer_name.length > 60) {
                setHelperTextName('發票抬頭長度限制 60 字元')
            } else {
                setHelperTextName('發票抬頭不可包含數字及特殊字元')
            }
        }
    }, [buyer_ubn, buyer_name])

    useEffect(() => {
        apiPaymentGetLovecodeInfo()
            .then((res) => res.data)
            .then((res) => {
                if (handleRespCode(res) === false) {
                    return
                } else {
                    setLoveCodeInfo(res.name + "(" + res.lovecode + ")")
                }
            })
            .catch((error) => handleAPICall(error))
    }, [])

    function validateForm() {
        if (selectedValue === useLoveCode) {
            return true
        } else if (selectedValue === personalInvoice){
            if (user_email === "") {
                return false
            }
            if (!isEmailValid(user_email)) {
                return false
            }
            if (selectedCarrierTypeValue === CarrierTypeMember) {
                return true
            }
            if (selectedCarrierTypeValue === CarrierTypePhone) {
                if (carrier === "") {
                    return false
                }
                return isValidPhoneCarrier(carrier)
            }
            if (selectedCarrierTypeValue === CarrierTypeCDC) {
                if (carrier === "") {
                    return false
                }
                return isValidCDCCarrier(carrier)
            }
            return true
        } else if (selectedValue === businessInvoice) {
            if (user_email === "") {
                return false
            }
            if (!isEmailValid(user_email)) {
                return false
            }
            if (buyer_ubn === "" || buyer_name === "") {
                return false
            }
            return !isUbnError(buyer_ubn, buyer_name) && !isNameError(buyer_ubn, buyer_name)
        }
    }

    const sunpay_form = useRef(null);
    const sunpay_form_web = useRef(null);
    const sunpay_form_checkValue = useRef(null);
    const sunpay_form_MN = useRef(null);
    const sunpay_form_OrderInfo = useRef(null);
    const sunpay_form_Td = useRef(null);
    const sunpay_form_sna = useRef(null);
    const sunpay_form_sdt = useRef(null);
    const sunpay_form_email = useRef(null);
    const sunpay_form_note1 = useRef(null);
    const sunpay_form_Card_Type = useRef(null);
    const sunpay_form_Country_Type = useRef(null);
    const sunpay_form_Term = useRef(null);
    const sunpay_form_DueDate = useRef(null);
    const sunpay_form_UserNo = useRef(null);
    const sunpay_form_ProductName1 = useRef(null);
    const sunpay_form_ProductPrice1 = useRef(null);
    const sunpay_form_ProductQuantity1 = useRef(null);
    const sunpay_form_AgencyType = useRef(null);
    const sunpay_form_AgencyBank = useRef(null);
    const sunpayFormSubmit = (data) => {
        sunpay_form_web.current.value = data.web;
        sunpay_form_checkValue.current.value = data.checkValue;
        sunpay_form_MN.current.value = data.MN;
        sunpay_form_OrderInfo.current.value = data.OrderInfo;
        sunpay_form_Td.current.value = data.Td;
        sunpay_form_sna.current.value = data.sna;
        sunpay_form_sdt.current.value = data.sdt;
        sunpay_form_email.current.value = data.email;
        sunpay_form_note1.current.value = data.note1;
        if (payType === "01") {
            sunpay_form_Card_Type.current.value = data.Card_Type;
            sunpay_form_Country_Type.current.value = data.Country_Type;
            sunpay_form_Term.current.value = data.Term;
        } else if (payType === "02") {
            sunpay_form_DueDate.current.value = data.DueDate;
            sunpay_form_UserNo.current.value = data.UserNo;
        } else if (payType === "03") {
            sunpay_form_DueDate.current.value = data.DueDate;
            sunpay_form_UserNo.current.value = data.UserNo;
            sunpay_form_ProductName1.current.value = data.ProductName1;
            sunpay_form_ProductPrice1.current.value = data.ProductPrice1;
            sunpay_form_ProductQuantity1.current.value = data.ProductQuantity1;
            sunpay_form_AgencyType.current.value = data.AgencyType;
            sunpay_form_AgencyBank.current.value = data.AgencyBank;
        } else if (payType === "04") {

        }
        sunpay_form.current.submit();
    }

    const onBuySubmit = async (recharge_id) => {
        // empty strings are invalid params, so we only send corresponding params to the server
        let params = {
            recharge_id,
            pay_type: payType,
            use_love_code,
        }
        if (!use_love_code) {
            params.user_email = user_email;
            if (buyer_ubn !== '' || buyer_name !== '') {
                params.buyer_ubn = buyer_ubn
                params.buyer_name = buyer_name
            }
            if (selectedValue === personalInvoice) {
                params.carrier_type = selectedCarrierTypeValue
                params.carrier = carrier
            }
        }
        await apiBuyRechargeSunpay(params)
            .then((res) => res.data)
            .then((res) => {
                if (handleRespCode(res) === false) {
                    return
                } else {
                    sunpayFormSubmit(res)
                }
            })
            .catch((error) => handleAPICall(error))
    };
    return (
        <>
            <Radio
                checked={selectedValue === useLoveCode}
                onChange={handleChangeRadio}
                value={useLoveCode}
                color="default"
            />
            <span className={classes.text}>
               捐贈至
            </span>
            <span className={classes.loveCode}>{loveCodeInfo}</span><br />
            <Radio
                checked={selectedValue === personalInvoice}
                onChange={handleChangeRadio}
                value={personalInvoice}
                color="default"
            />
            <span className={classes.text}>
                個人電子發票
            </span><br />
            <Radio
                checked={selectedValue === businessInvoice}
                onChange={handleChangeRadio}
                label="businessInvoice"
                value={businessInvoice}
                color="default"
            />
            <span className={classes.text}>
                公司發票
            </span><br />
            <form className={classes.form} >
                 <Select
                    variant="outlined"
                    hidden={selectedValue !== personalInvoice}
                    color="primary"
                    className={classes.select}
                    fullWidth
                    label="載具類型"
                    native
                    defaultValue={carrierTypeDefaultValue}
                    value={selectedCarrierTypeValue}
                    onChange={handleChangeCarrierType}
                    style={{
                        height: '40px',
                        marginLeft: '-0.5px',
                        marginTop: '15px',
                        color: 'black',
                        paddingLeft: '0px',
                    }}
                    inputProps={{
                        name: 'age',
                        id: 'outlined-age-native-simple',
                    }}
                    SelectDisplayProps={{ classes: { paper: classes.select } }}
                    MenuProps={{
                        classes: { paper: classes.select },
                    }}
                >
                  <option value={1}>會員載具(用戶可自行歸戶)</option>
                  <option value={2}>手機載具條碼</option>
                  <option value={3}>自然人憑證</option>
                </Select>
                <TextField
                    hidden={selectedValue !== personalInvoice || selectedCarrierTypeValue !== CarrierTypePhone}
                    required={true}
                    style={{ height: '52px', marginTop: '0px', marginLeft: '1px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    disabled={inputDisable}
                    margin="normal"
                    id="carrier_phone"
                    label={"請輸入手機載具"}
                    placeholder="範例：/ABC+123"
                    type="name"
                    value={carrier}
                    error={phoneCarrierError}
                    helperText={phoneCarrierHelperText}
                    fullWidth
                    FormHelperTextProps={{
                        className: classes.helperText
                    }}
                    onChange={(e) => {
                        setCarrier(e.target.value)
                        const t = e.target.value
                        if (t === "" || isValidPhoneCarrier(t)) {
                            setPhoneCarrierError(null)
                            setPhoneCarrierHelperText('')
                            return
                        }
                        setPhoneCarrierError(true)
                        setPhoneCarrierHelperText('範例：/ABC+123')
                    }}
                />
                <TextField
                    hidden={selectedValue !== personalInvoice || selectedCarrierTypeValue !== CarrierTypeCDC}
                    required={true}
                    style={{ height: '52px', marginTop: '0px', marginLeft: '1px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    disabled={inputDisable}
                    margin="normal"
                    id="carrier_cdc"
                    label={"請輸入自然人憑證"}
                    placeholder="16碼英數字，前面兩碼為大寫英文字母"
                    type="name"
                    value={carrier}
                    error={CDCCarrierError}
                    helperText={CDCCarrierHelperText}
                    fullWidth
                    FormHelperTextProps={{
                        className: classes.helperText
                    }}
                    onChange={(e) => {
                        setCarrier(e.target.value)
                        const t = e.target.value
                        if (t === "" || isValidCDCCarrier(t)) {
                            setCDCCarrierError(null)
                            setCDCCarrierHelperText('')
                            return
                        }
                        setCDCCarrierError(true)
                        setCDCCarrierHelperText('16碼英數字，前面兩碼為大寫英文字母')
                    }}
                />
                <TextField
                    hidden={selectedValue === useLoveCode}
                    style={{ height: '50px', marginLeft: '1px', marginTop: '2px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor,
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    required={isEmailRequired}
                    disabled={inputDisable}
                    id={user_email}
                    label="請輸入Email"
                    type={email}
                    value={user_email}
                    error={!isEmailValid(user_email)}
                    fullWidth
                    onChange={(e) => setUserMail(e.target.value)}
                />
                <TextField
                    hidden={selectedValue !== businessInvoice}
                    required={true}
                    style={{ height: '50px', marginLeft: '1px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    disabled={inputDisable}
                    margin="normal"
                    id="name"
                    label="請輸入統編"
                    placeholder="買受人統一編號，純數字"
                    type="name"
                    value={buyer_ubn}
                    error={isUbnError(buyer_ubn, buyer_name)}
                    helperText={helperTextUbn}
                    fullWidth
                    FormHelperTextProps={{
                        className: classes.helperText
                    }}
                    onChange={(e) => setBuyerUbn(e.target.value)}
                />
                <TextField
                    hidden={selectedValue !== businessInvoice}
                    required={true}
                    style={{ height: '50px', marginLeft: '1px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    disabled={inputDisable}
                    margin="normal"
                    id="title"
                    label="請輸入發票抬頭"
                    placeholder="發票抬頭長度限制 60 字元"
                    type="name"
                    value={buyer_name}
                    error={isNameError(buyer_ubn, buyer_name)}
                    helperText={helperTextName}
                    fullWidth
                    FormHelperTextProps={{
                        className: classes.helperText
                    }}
                    onChange={(e) => setBuyerName(e.target.value)}
                />
            </form>

            <Button
                type="submit"
                onClick={() => onBuySubmit(id)}
                disabled={!validateForm()}
                fullWidth
                className={classes.button}
                variant="contained"
            >
                前往付款
            </Button>

            <form id="sunpay_form" ref={sunpay_form} name='paynow' method='post' action={sunpayEpointAPI}>
                <input type='hidden' ref={sunpay_form_web} id="web" name='web' value='' />
                <input type='hidden' ref={sunpay_form_checkValue} id="ChkValue" name='ChkValue' value='' />
                <input type='hidden' ref={sunpay_form_MN} id="MN" name='MN' value='' />
                <input type='hidden' ref={sunpay_form_OrderInfo} id="OrderInfo" name='OrderInfo' value='' />
                <input type='hidden' ref={sunpay_form_Td} id="Td" name='Td' value='' />
                <input type='hidden' ref={sunpay_form_sna} id="sna" name='sna' value='' />
                <input type='hidden' ref={sunpay_form_sdt} id="sdt" name='sdt' value='' />
                <input type='hidden' ref={sunpay_form_email} id="email" name='email' value='' />
                <input type='hidden' ref={sunpay_form_note1} id="note1" name='note1' value='' />
                <input type='hidden' ref={sunpay_form_Card_Type} id="Card_Type" name='Card_Type' value='' />
                <input type='hidden' ref={sunpay_form_Country_Type} id="Country_Type" name='Country_Type' value='' />
                <input type='hidden' ref={sunpay_form_Term} id="Term" name='Term' value='' />
                <input type='hidden' ref={sunpay_form_DueDate} id="DueDate" name='DueDate' value='' />
                <input type='hidden' ref={sunpay_form_UserNo} id="UserNo" name='UserNo' value='' />
                <input type='hidden' ref={sunpay_form_ProductName1} id="ProductName1" name='ProductName1' value='' />
                <input type='hidden' ref={sunpay_form_ProductPrice1} id="ProductPrice1" name='ProductPrice1' value='' />
                <input type='hidden' ref={sunpay_form_ProductQuantity1} id="ProductQuantity1" name='ProductQuantity1' value='' />
                <input type='hidden' ref={sunpay_form_AgencyType} id="AgencyType" name='AgencyType' value='' />
                <input type='hidden' ref={sunpay_form_AgencyBank} id="AgencyBank" name='AgencyBank' value='' />
            </form>
        </>
    )
};

export default SunpayForm;

import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import BackIcon from '../../components/BackIcon';
const useStyles = makeStyles((theme) => ({
    title: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: '2rem',
    },
    pageName: {
        display: 'flex',
        alignItems: 'center',
    },
    logo: {
        height: '1rem',
        width: '1rem',
        marginRight: '-0.5rem',
    },
    close: {
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
    },
}));
interface DialogProps {
    showBack: boolean
    className?: string
    title?: string
    backOnClick(): void
    onClose(): void
}
const DialogTitle = ({ className, showBack, title, backOnClick, onClose }: DialogProps) => {
    const classes = useStyles();
    return (
        <div className={className}>
            <div className={classes.pageName}>
                {showBack ?
                    <BackIcon onClick={backOnClick} /> :
                    <img
                        src={`${process.env.PUBLIC_URL}/logo.png`}
                        alt="logo"
                        className={classes.logo}
                    />
                }
                <div style={{ marginLeft: '1rem' }}><b>{title}</b></div>
            </div>
            <div onClick={onClose} className={classes.close}>
                <img src={'https://web-cdn.halu.love/images/general/close_25x25.png'} alt="close" />
            </div>
        </div>
    )
}
export default DialogTitle;
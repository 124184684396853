import React, { memo, useState, useEffect } from 'react';
import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DownloadCard from './DownloadCard';
import EventBanner from '../Events/EventBanner';
import UserInfo from './UserInfo';
import {
    apiGetUserInfoAnony,
    apiGetLiveList,
    handleAPICall
} from '../../service/api';
import Player from './Player';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: '98vh',
        display: 'flex',
        padding: '1rem',
        justifyContent: 'center',
        backgroundColor: '#f3f3f3',
    },
    card: {
        color: '#707070',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0 1rem 1rem 1rem',
        padding: '.5rem',
        backgroundColor: '#fff',
        minWidth: '220px',
        maxWidth: '300px',
    },
    title: {
        padding: '5px',
        width: '100%',
    },
    player: {
        margin: '0 1rem 1rem 1rem',
        padding: '.5rem',
        backgroundColor: '#fff',
        minWidth: '220px',
        maxWidth: '500px',
    },
    infoContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-start',
        minWidth: '220px',
        maxWidth: '300px',
    },
    banner: {
        margin: '0 1rem 1rem 1rem',
        minWidth: '220px',
        maxWidth: '300px',
    }
}));
interface liveListProps {
    live_title: string
    replay_url: string
    account: string
    nickname: string
    pic_head_low: string
    like_count: number
    live_cover: string
}
const LiveRoom = () => {
    const classes = useStyles();
    const [liveListState, setLiveListState] = useState<liveListProps>();
    const [aboutMe, setAboutMe] = useState('');
    const [account, setAccount] = useState('');
    const getLiveList = async () => {
        await apiGetLiveList()
            .then((res) => res.data)
            .then((res) => {
                const resp: liveListProps = res.live_list[0]
                setLiveListState(resp)
                setAccount(resp.account)
            })
            .catch((error) => handleAPICall(error))
    }
    const getAboutMe = async (account: string) => {
        await apiGetUserInfoAnony(account)
            .then((res) => res.data)
            .then((res) => {
                setAboutMe(res.signature)
            })
            .catch((error) => handleAPICall(error))
    }
    useEffect(() => {
        getLiveList()
        getAboutMe(account)
    }, [account])
    return (
        <div className={classes.root}>
            <Card className={classes.card} />
            <Card className={classes.player}>
                <Player url={liveListState?.replay_url} poster={liveListState?.live_cover} />
            </Card>
            <div className={classes.infoContent}>
                <Card className={classes.card}>
                    <div className={classes.title}>
                        {liveListState?.live_title}
                    </div>
                </Card>
                <Card className={classes.card}>
                    <UserInfo
                        pic_url={liveListState?.pic_head_low}
                        user_name={liveListState?.nickname}
                        like_count={liveListState?.like_count}
                        about_me={aboutMe}
                    />
                </Card>
                <div className={classes.banner}>
                    <EventBanner
                        skeletonWidth={300}
                        skeletonHeight={180}
                        carouselMaxWidth={300}
                    />
                </div>
                <Card className={classes.card}>
                    <DownloadCard />
                </Card>
            </div>
        </div>
    )
}

export default memo(LiveRoom);
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { paymentTypeGomypay, paymentTypePayNow, paymentTypeSinopac, paymentTypeSunpay } from './Const';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    baseBtnCss: {
        filter: 'opacity(0.5)',
        display: 'inline-block'
    },
    activeCss: {
        filter: 'opacity(1)',
    },
    imgCss: {
        height: '3.5rem',
        width: '11rem',
        marginRight: '0.5rem',
        borderRadius: '5px',
        [theme.breakpoints.down('xs')]: {
            height: '1.75rem',
            width: '5.5rem',
        },
    },
    descCss: {
        display: 'block',
        color: '#FFFFFF',
        fontSize: '.7rem',
        textAlign: 'center',
        lineHeight: '20px',
        fontWeight: 'bold',
    }
})

const useStyles = makeStyles(styles);

const PaymentSelectButton = ({ payment, desc, selected, ...props }) => {
    const classes = useStyles();
    var imgSrc = "https://web-cdn.halu.love/images/purchase/NewebPay.png"
    // if (payment === paymentTypePayNow) {
    //     imgSrc = `https://web-cdn.halu.love/images/purchase/PayNow.png`
    // }
    if (payment === paymentTypeSunpay) {
        imgSrc = "https://web-cdn.halu.love/images/purchase/sunpay.png"
    } else if (payment === paymentTypeSinopac) {
        imgSrc = "https://web-cdn.halu.love/images/purchase/sinopac.png"
    } else if (payment === paymentTypeGomypay) {
        imgSrc = "https://web-cdn.halu.love/images/purchase/gomypay-img-v3.jpg"
    }

    return (
        <div>
        <Button
          component={NavLink}
          className={`${classes.baseBtnCss} ${selected ? classes.activeCss : ''}`}
          to={`/purchase?payment=${payment}`}
          disableElevation
          disableRipple
          {...props}
        >
          <img src={imgSrc} alt="payBtn" className={classes.imgCss}/>
        </Button>
        <span className={classes.descCss}>{desc}</span>
        </div>
      );

    return (
        <div>
            <img src={imgSrc} alt="payBtn" />
            <span className={classes.descCss}>{desc}</span>
        </div>
    );
};

export default PaymentSelectButton;

import React, { useState } from "react";
import {
    apiPhoneCaptcha,
    handleAPICall,
} from '../../service/api';
import { useDispatch } from 'react-redux';
import { setErrorMsg, setSnackbarOpen } from '../../actions';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CountdownTimer from '../../components/CountdownTimer';
import { resetPassword, smsLogin } from "../Const";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    overrides: {
        // 样式表的名字 ⚛️
        MuiOutlinedInput: {
            // 规则的名字
            input: {
                // 一些 CSS
                backgroundColor: '#fff'
            },
        }
    },
});

const useStyles = makeStyles((theme) => ({
    variBtn: {
        color: '#fff',
        backgroundColor: 'black',
        "&:hover": {
            color: '#fff',
            backgroundColor: 'black'
        }
    },
}));
export interface SMSCaptchaProps {
    type: string
    area_code: string
    phone: string
    disabled: boolean
    onChange(e: string): void
}
const SMSCaptcha = ({
    type,
    area_code,
    phone,
    disabled,
    onChange }: SMSCaptchaProps) => {
    const classes = useStyles();
    const [sent, setSent] = useState(false);
    const [seconds, setSeconds] = useState(-1);
    const [value, setValue] = useState('');
    const dispatch = useDispatch();
    const login = 1;
    const edit_password = 4;
    let captcha_type: number

    const sendSMSCaptcha = async () => {
        switch (type) {
            case resetPassword:
                captcha_type = edit_password
                break
            case smsLogin:
                captcha_type = login
                break
            default:
                break
        }
        await apiPhoneCaptcha({
            area_code,
            phone,
            captcha_type,
        })
            .then((res) => res.data)
            .then((resp) => {
                if (resp.code === 0) {
                    setSent(true)
                    setSeconds(60)
                    return
                } else {
                    setSent(false)
                    dispatch(setErrorMsg(`${resp.msg}`))
                    dispatch(setSnackbarOpen())
                }
            })
            .catch((error) => handleAPICall(error))
    }
    const disableCaptcha = disabled || sent;

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(e.target.value)
        onChange(e.target.value)
    }
    const handleTimesUp = () => {
        setSent(false)
        setSeconds(-1)
    }
    return (<>
        <ThemeProvider theme={theme}>
            <InputLabel>
                輸入簡訊驗證碼
            </InputLabel>
            <OutlinedInput
                id="SMSCaptcha"
                type="text"
                value={value}
                autoComplete="current-password"
                labelWidth={110}
                onChange={(e) => handleChange(e)}
                endAdornment={
                    <InputAdornment position="end" disableTypography>
                        <Button
                            className={classes.variBtn}
                            variant="contained"
                            disabled={disableCaptcha}
                            onClick={() => sendSMSCaptcha()}
                        >
                            獲取驗證碼
                            <CountdownTimer sec={seconds} timesUp={handleTimesUp} />
                        </Button>
                    </InputAdornment>}
            />
        </ThemeProvider>
    </>)
}

export default SMSCaptcha;
import React, { memo, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { css } from '@emotion/css';
import Body from './Body';
import Appbar from './Topbar/Topbar';
import ErrorHandling from './error/error';
import { handleAPICall } from './service/api';
import { remoteConfig } from './firebase';
import { grey } from '@material-ui/core/colors';

import {
  createMuiTheme,
  CssBaseline,
  MuiThemeProvider,
} from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: grey[900],
    },
    secondary: {
      main: '#FEDC62',
    },
    text: {
      primary: '#fff',
    },
    background: {
      default: grey[900],
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'Roboto',
      'Microsoft JhengHei',
      'sans-serif',
    ].join(','),
    fontSize: 16,
  },
});

const mainCss = css`
  color: #fff;
  background-color: ${grey[900]};
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
`;

function App() {
  const enableLoginAndPurchase = window._env_.LOGIN_PURCHASE_CONFIG;
  const newebpay_outage = window._env_.NEWEBPAY_OUTAGE_CONFIG;
  const currentLoginConfig = remoteConfig.getBoolean(enableLoginAndPurchase);
  const currentNewebPayConfig = remoteConfig.getBoolean(newebpay_outage);
  const [showAppBar, setShowAppBar] = useState(true);
  const [showAppBarTabs, setShowAppBarTabs] = useState(true);
  const [remoteConfigEnableLoginAndPurchase, setRemoteConfigEnableLoginAndPurchase] = useState(currentLoginConfig);
  const [remoteNewebpayOutage, setRemoteNewebpayOutage] = useState(currentNewebPayConfig);

  remoteConfig.fetchAndActivate()
    .then(() => {
      const fetchConfigValue = remoteConfig.getBoolean(enableLoginAndPurchase)
      const fetchNewebPayConfig = remoteConfig.getBoolean(newebpay_outage)
      setRemoteConfigEnableLoginAndPurchase(fetchConfigValue)
      setRemoteNewebpayOutage(fetchNewebPayConfig)
    })
    .catch((error) => handleAPICall(error))

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <Router>
          {showAppBar && <Appbar
            showTabs={showAppBarTabs}
            enableLoginAndPurchase={remoteConfigEnableLoginAndPurchase}
            newebpayOutage={remoteNewebpayOutage}
          />}
          <Body
            className={mainCss}
            setShowAppBar={setShowAppBar}
            setShowAppBarTabs={setShowAppBarTabs}
            isPurchaseRouters={remoteConfigEnableLoginAndPurchase && !remoteNewebpayOutage}
          />
        </Router>
      </MuiThemeProvider>
      <ErrorHandling />
    </div>
  );
}

export default memo(App);
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';

export function validPhone(area_code, phone) {
    if ( phone === '' ) { 
        return false
    }
    return isValidPhoneNumber('+' + area_code + phone) && isPossiblePhoneNumber('+' + area_code + phone)
}
export function validPassword(pwd) {
    return pwd.length >= 8
}
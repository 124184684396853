import React, { memo } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { setSnackbarClose } from "../actions";
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ErrorHandling = () => {
    const stateErrorMsg = useSelector(state => state.errorMsg)
    const stateOpen = useSelector(state => state.isSnackbarOpen)
    const dispatch = useDispatch();
    return (
        <div>
            <Snackbar
                open={stateOpen}
                autoHideDuration={3000}
                onClose={() => dispatch(setSnackbarClose())}
            >
                <Alert
                    severity="error"
                    action={
                        <div onClick={() => dispatch(setSnackbarClose())}>
                            <img src={'https://web-cdn.halu.love/images/general/whiteClose_25x25.png'} alt="close" />
                        </div>}
                >
                    {stateErrorMsg}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default memo(ErrorHandling);
import React, { useEffect, useState, memo, useMemo } from 'react';
import { useLocation } from 'react-router';
import { css } from '@emotion/css';
import {
    paymentTypeNewebPay,
    paymentTypePayNow,
    paymentTypeSinopac,
    paymentTypeSunpay,
    newebPayDesc,
    sinopacDesc,
    sunpayDesc,
    webpayNewebPay,
    webpayPayNow,
    webpaySunpay,
    webpaySinopac,
    sunpayPayTypePaycode,
    sunpayPayTypeWebATM,
    sunpayPayTypePaycodeAmountLimit,
    sunpayPayTypeWebATMAmountLimit,
    sinpacPayTypeATMAmountLimit,
    sinopacPayTypeATM,
    sinopacPayTypeWebATM,
    paymentTypeGomypay,
    gomypayDesc,
    gomypayPayTypeConvStoreBarcode,
    gomypayPayTypeConvStoreBarcodeAmountLimit,
    gomypayPayTypeConvStorePaycode,
    gomypayPayTypeConvStorePaycodeAmountLimit,
    gomypayPayTypeVitrualAccount,
    gomypayPayTypeVirtualAccountAmountLimit,
    gomypayPayTypeWebATM,
    gomypayPayTypeWebAtmAmountLimit,
    webpayGomypay,
    payNowDesc,
    gomypayPayTypeCreditCard,
    gomypayPayTypeCreditCardAmountLimit,
    gomypayPayTypeUnionPay,
    gomypayPayTypeUnionPayAmountLimit,
    newebPayAmountLimit
} from './Const';
import PaymentSelectButton from './PaymentSelectButton';
import NewebCards from './NewebDialog';
import PaynowCards from './PaynowDialog';
import SunpayDialogsEzpay from './SunpayDialogEzpay';
import GomypayDialogs from './GomypayDialog';
import SinopacDialogsEzpay from './SinopacDialogEzpay';
import { apiGetRechargeList } from '../../service/api';
import SkeletonMap from '../../components/SkeletonMap';
import { makeStyles } from '@material-ui/core/styles';
import {
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
} from "@material-ui/core";
import PaynowDialogs from './PaynowDialog';

const purchaseCss = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: start;
    padding-top: 20px;
`;
// const priceContainerCss = css`
//   display: grid;
//   width: 100%;
//   align-items: center;
//   justify-content: center;
//   grid-template-columns: repeat(auto-fit, [col-start] 291px [col-end]);
//   grid-column-gap: 16px;
//   grid-row-gap: 12px;
//   padding: 10px;
//   @media (min-width: 600px) {
//     align-self: stretch;
//   }
// `;
const paymentGroupCss = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

const paymentTypeGroupCss = css`
  display: grid;
  flex-direction: row;
  width: 100%;
  justify-content: left;
  padding: 0 0 0 60px;
`;


const useStyles = makeStyles((theme) => ({
    paymentTypeLabel: {
        color: 'white',
        '&$checked': {
          color: '#000'
        },
        '&.Mui-focused': {
          color: 'white'
        },
    },
    radio: {
        color: 'white',
        '&$checked': {
          color: 'white'
        },
        '& .MuiSvgIcon-root': {
            fontSize: 14,
        },
    },
    checked: {
    }
}));

const coinContainerCss = css`
  display: grid;
  width: 100%;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(140px, 100px));
  grid-column-gap: 32px;
  grid-row-gap: 16px;
  align-self: center;
  padding: 20px 0 20px 0px;
`;

const Purchase = () => {
    const classes = useStyles();
    const { search } = useLocation();

    const [rechargeList, setRechargeList] = useState([]);
    const [displayRechargeList, setDisplayRechargeList] = useState([]);
    const [isLoad, setIsLoad] = useState(true);
    const [payTypeValue, setPayTypeValue] = useState('');
    const [payTypeOptions, setPayTypeOptions] = useState([]);
    const payment = useMemo(
        () => new URLSearchParams(search).get('payment') || paymentTypeNewebPay,
        [search]
      );

    const handlePayTypeChange = (e) => {
        setPayTypeValue(e.target.value);
    }

    useEffect(() => {
        getRechargeList(payment)
    }, [payment]);
    useEffect(() => {
        if (payTypeOptions.length > 0) {
            setPayTypeValue(payTypeOptions[0].pay_type)
        }
    }, [payTypeOptions])
    const getRechargeList = async (payment) => {
        let web_pay = 0;
        switch (payment) {
            case paymentTypeNewebPay:
                web_pay = webpayNewebPay;
                break;
            // case paymentTypePayNow:
            //     web_pay = webpayPayNow;
            //     break;
            // case paymentTypeSunpay:
            //     web_pay = webpaySunpay;
            //     break;
            // case paymentTypeSinopac:
            //     web_pay = webpaySinopac;
            //     break;
            case paymentTypeGomypay:
                web_pay = webpayGomypay;
                break;
            default:
                setRechargeList([]);
                return;
        }
        const resp = await apiGetRechargeList({
            params: {
                web_pay,
            },
        }).then((res) => res.data);

        const data = JSON.parse(
            resp.substring(resp.indexOf('(') + 1, resp.indexOf(')'))
        );
        setRechargeList(data.rechargeList);
        if (data.pay_types != null) {
            const payTypeOptions = data.pay_types.map((pay_type, index) => {
                return {
                    pay_type: pay_type,
                    pay_type_name: data.pay_type_names[index],
                }
            })
            setPayTypeOptions(payTypeOptions)
        }
        setIsLoad(false);
    };

    const payTypeLabels = () => {
        if (payment === paymentTypeSunpay || payment === paymentTypeSinopac || payment === paymentTypeGomypay) {
            const labels = payTypeOptions.map((option, index) => {
                return (<FormControlLabel
                            value={option.pay_type}
                            control={<Radio
                                        size="small"
                                        disableRipple
                                        classes={{root: classes.radio, checked: classes.checked}}
                            />}
                            label={option.pay_type_name}
                        />)
            })
            return <FormControl>
                <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    classes={{root: classes.paymentTypeLabel}}
                >
                    {/* 付款方式 */}
                </FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={payTypeValue}
                    onChange={handlePayTypeChange}
                >
                {labels}
                </RadioGroup>
            </FormControl>
        }
    }

    useEffect(() => {
        const newDisplayRechargeList = []
        rechargeList.map((elm, index) => {
            const { pay_money } = elm;
            if (payment === paymentTypeNewebPay) {
                if (pay_money > newebPayAmountLimit) {
                    return null
                }
            } else if (payment === paymentTypeSunpay) {
                if (payTypeValue === sunpayPayTypePaycode && pay_money > sunpayPayTypePaycodeAmountLimit) {
                    return null
                }
                if (payTypeValue === sunpayPayTypeWebATM && pay_money > sunpayPayTypeWebATMAmountLimit) {
                    return null
                }
            } else if (payment === paymentTypeSinopac) {
                if ((payTypeValue === sinopacPayTypeATM || payTypeValue === sinopacPayTypeWebATM)
                    && pay_money > sinpacPayTypeATMAmountLimit) {
                    return null
                }
            } else if (payment === paymentTypeGomypay) {
                if (payTypeValue === gomypayPayTypeCreditCard && pay_money > gomypayPayTypeCreditCardAmountLimit) {
                    return null
                }
                if (payTypeValue === gomypayPayTypeUnionPay && pay_money > gomypayPayTypeUnionPayAmountLimit) {
                    return null
                }
                if (payTypeValue === gomypayPayTypeConvStoreBarcode && pay_money > gomypayPayTypeConvStoreBarcodeAmountLimit) {
                    return null
                }
                if (payTypeValue === gomypayPayTypeWebATM && pay_money > gomypayPayTypeWebAtmAmountLimit) {
                    return null
                }
                if (payTypeValue === gomypayPayTypeVitrualAccount && pay_money > gomypayPayTypeVirtualAccountAmountLimit) {
                    return null
                }
                if (payTypeValue === gomypayPayTypeConvStorePaycode && pay_money > gomypayPayTypeConvStorePaycodeAmountLimit) {
                    return null
                }
            }
            newDisplayRechargeList.push(elm)
            return elm
        })
        setDisplayRechargeList(newDisplayRechargeList)
    }, [rechargeList, payTypeValue, payment]);

    return (
        <div className={purchaseCss}>
            <div className={paymentGroupCss}>
                {/* <PaymentSelectButton payment={paymentTypeSunpay} desc={sunpayDesc} selected={paymentTypeSunpay === payment}/> */}
                <PaymentSelectButton payment={paymentTypeNewebPay} desc={newebPayDesc} selected={paymentTypeNewebPay === payment}/>
                <PaymentSelectButton payment={paymentTypeGomypay} desc={gomypayDesc} selected={paymentTypeGomypay === payment}/>
                {/* <PaymentSelectButton payment={paymentTypePayNow} desc={payNowDesc} selected={paymentTypePayNow === payment}/> */}
                {/* <PaymentSelectButton payment={paymentTypeSinopac} desc={sinopacDesc} selected={paymentTypeSinopac === payment}/> */}
            </div>
            <div className={paymentTypeGroupCss}>
                {payTypeLabels()}
            </div>
            <div className={coinContainerCss}>
                    {isLoad && <SkeletonMap length={16} width={291} height={70} />}
                    {displayRechargeList.map((elm, index) => {
                        const { id, buy_num, pay_money } = elm;
                        if (payment === paymentTypeNewebPay) {
                            return (<NewebCards coins={buy_num} price={pay_money} id={id} key={id} />)
                        } else if (payment === paymentTypeGomypay) {
                            return (<GomypayDialogs payType={payTypeValue} coins={buy_num} price={pay_money} id={id} key={id} />)
                        } else {
                            return (null)
                        }
                        {/* if (payment === paymentTypePayNow) {
                            return (<PaynowCards coins={buy_num} price={pay_money} id={id} key={id} />)
                        } else if (payment === paymentTypeNewebPay) {
                            return (<NewebCards coins={buy_num} price={pay_money} id={id} key={id} />)
                        } else if (payment === paymentTypeSunpay) {
                            return (<SunpayDialogsEzpay payType={payTypeValue} coins={buy_num} price={pay_money} id={id} key={id} />)
                        } else if (payment === paymentTypeSinopac) {
                            return (<SinopacDialogsEzpay payType={payTypeValue} coins={buy_num} price={pay_money} id={id} key={id} />)
                        } else {
                            return (null)
                        } */}
                    })}
            </div>
        </div>
    );
};

export default memo(Purchase);

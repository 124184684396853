import React, { memo, useEffect, useState } from 'react';
import axios from 'axios';

const Policies = () => {
  const [html, setHtml] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/policies.html`).then((res) => {
      setHtml({ __html: res.data });
    });
  }, []);

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center' }}
      dangerouslySetInnerHTML={html}
    ></div>
  );
};

export default memo(Policies);

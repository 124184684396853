export const paymentTypeNewebPay = 'newebpay';
export const paymentTypePayNow = 'paynow';
export const paymentTypeSunpay = 'sunpay';
export const paymentTypeSinopac = 'sinopac';
export const paymentTypeGomypay = 'gomypay';

export const newebPayDesc = '藍新金流服務平台';
export const payNowDesc = 'Paynow 立吉富線上金流';
export const sunpayDesc = '紅陽支付';
export const sinopacDesc = '永豐銀行';
export const gomypayDesc = '台灣萬事達金流';

export const webpayNewebPay = 11;
export const webpayPayNow = 14;
export const webpaySunpay = 15;
export const webpaySinopac = 16;
export const webpayGomypay = 19;

export const newebPayAmountLimit = 180000;

export const payNowPayTypeForeignCard = '01';

export const sunpayPayTypeCreditCard = '01';
export const sunpayPayTypePaycode = '02';
export const sunpayPayTypeATM = '03';
export const sunpayPayTypeWebATM = '04';

export const sunpayPayTypePaycodeAmountLimit = 20000;
export const sunpayPayTypeWebATMAmountLimit = 30000;

// 0.信用卡 1.銀聯卡 2.超商條碼 3.WebAtm 4.虛擬帳號 6.超商代碼 7.LinePay
// 2, 3, 7 不支援
export const gomypayPayTypeCreditCard = '0';
export const gomypayPayTypeUnionPay = '1';
export const gomypayPayTypeConvStoreBarcode = '2';
export const gomypayPayTypeWebATM = '3';
export const gomypayPayTypeVitrualAccount = '4';
export const gomypayPayTypeConvStorePaycode = '6';

export const gomypayPayTypeCreditCardAmountLimit = 180000;
export const gomypayPayTypeUnionPayAmountLimit = 45000;
export const gomypayPayTypeConvStoreBarcodeAmountLimit = 45000;
export const gomypayPayTypeWebAtmAmountLimit = 45000;
export const gomypayPayTypeVirtualAccountAmountLimit = 45000;
export const gomypayPayTypeConvStorePaycodeAmountLimit = 20000;

export const sinopacPayTypeCreditCard = '01';
export const sinopacPayTypeUnionPay = '02';
export const sinopacPayTypeATM = '03';
export const sinopacPayTypeWebATM = '04';
export const sinopacPayTypeConvStore = '05';
export const sinopacPayTypeMobile = '06';
export const sinopacPayTypeLine = '07';

export const sinpacPayTypeConvStoreAmountLimit = 20000;
export const sinpacPayTypeATMAmountLimit = 30000;

export const useLoveCode = "use_love_code";
export const email = "email"; // deprecated
export const personalInvoice = "personal_invoice";
export const businessInvoice = "business_invoice";
export const CarrierTypeMember = 1;
export const CarrierTypePhone = 2;
export const CarrierTypeCDC = 3;

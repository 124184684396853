import React from 'react';

// Workaround:
// - issue: https://trello.com/c/atHol1JI
// While using browsers in mobile, cookies might become can't not access if the page is redirected (using href) from https://dev-api-286906.venuslive.tv/pay/NewebPayJump,
// which means the original cookie might be hide in current window and we can't set cookie after login again without reloading the page. (It looks like the domain is not venuslive.com.tw)
// Redirect components are workaround to reload the page to let the cookie works.
//
// Note: The redirect href in `/pay/NewebPayJump` are changed
// - changed `/purchase/newebpay` to `/purchase/newebpay/redirect`
// - changed `/purchase/paynow` to `/purchase/paynow/redirect`

export const paymentRedirect = () => {
	window.location.replace('/purchase');
	return (<></>)
}

export const NewebpayRedirect = () => {
	window.location.replace('/purchase/newebpay');
	return (<></>)
}

export const SunpayRedirect = () => {
	window.location.replace('/purchase/sunpay');
	return (<></>)
}
import React from 'react'
import { ProdEventDict, DevEventDict } from './EventConfig'

const EventPages = (props) => {
    var event = ProdEventDict[props.match.params.eventID]
    if (window._env_.ENV === 'dev') {
        event = DevEventDict[props.match.params.eventID]
    }
    if (event == null) {
        return <div>活動不存在在 Event does not Exist</div>
    }
    return <event.component eventID={props.match.params.eventID} options={event.options} />
}

export default EventPages
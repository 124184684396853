import React, { useState, useRef, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { Button, } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import {
    apiBuyRechargeNewebPay,
    apiPaymentGetLovecodeInfo,
    newWebPayMPGAPI,
    handleAPICall,
    handleRespCode,
} from '../../service/api';
import {
    useLoveCode,
    email,
    personalInvoice,
    businessInvoice,
    CarrierTypeMember,
    CarrierTypePhone,
    CarrierTypeCDC
} from './Const';
import { isUbnError, isNameError, isEmailValid, isValidPhoneCarrier, isValidCDCCarrier } from './isValid';


const useStyles = makeStyles((theme) => ({
    form: {
        fontSize: '.6rem',
    },
    text: {
        padding: '2px',
        color: 'black',
    },
    textInputColor: {
        color: 'black'
    },
    loveCode: {
        padding: '2px',
        color: '#007bff',
    },
    button: {
        lineHeight: '3',
        marginTop: '1rem',
        border: 'none',
        color: '#ffffff',
        backgroundColor: 'black',
        "&:hover": { color: '#ffffff', backgroundColor: 'black', },
    },
    placeholder: {
        color: '#cc0000',
    },
    helperText: {
        color: '#cc0000',
        height: '50px',
    },
    select: {
        "& ul": {
            backgroundColor: "#000000",
        },
        "& li": {
            // fontSize: 12,
        },
        // marginTop: '1rem',
        // '&:before': {
        //     borderColor: 'red',
        // },
        // '&:after': {
        //     borderColor: 'red',
        // }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    // carrierLabel: {
    //     marginTop: theme.spacing(5),
    //     margin: theme.spacing(5.5, 0,0,0),
    //     height: '2px',
    //     color: '#000000',
    //     fontSize: '0.85rem',
    // }
    context: {
        marginTop: '1rem',
        color: 'black',
    },
}));
const DemoForm = ({ id, ...props }) => {
    const classes = useStyles();
    const carrierTypeDefaultValue = 1
    const [selectedValue, setSelectedValue] = useState(useLoveCode);
    const [selectedCarrierTypeValue, setCarrierTypeValue] = useState(carrierTypeDefaultValue);
    const [carrier, setCarrier] = useState('');
    const [user_email, setUserMail] = useState('');
    const [buyer_ubn, setBuyerUbn] = useState('');
    const [buyer_name, setBuyerName] = useState('');
    const [use_love_code, setUseLoveCode] = useState(selectedValue === useLoveCode);
    const [inputDisable, setInputDisable] = useState(true);
    const [isEmailRequired, setEmailRequired] = useState(false);
    const [helperTextUbn, setHelperTextUbn] = useState('')
    const [helperTextName, setHelperTextName] = useState('')
    const [loveCodeInfo, setLoveCodeInfo] = useState('');
    const [phoneCarrierError, setPhoneCarrierError] = useState(null);
    const [CDCCarrierError, setCDCCarrierError] = useState(null);
    const [phoneCarrierHelperText, setPhoneCarrierHelperText] = useState(null);
    const [CDCCarrierHelperText, setCDCCarrierHelperText] = useState(null);

    const handleChangeRadio = (e) => {
        const target = e.target.value
        if (target === useLoveCode) {
            setSelectedValue(e.target.value)

            setHelperTextUbn('')
            setHelperTextName('')
            setUseLoveCode(true)
            setInputDisable(true)
            setUserMail('')
            setBuyerName('');
            setBuyerUbn('');
            setEmailRequired(false)
            setCarrierTypeValue(carrierTypeDefaultValue)
            setCarrier('')
            setPhoneCarrierHelperText('')
            setCDCCarrierHelperText('')
        } else if (target === personalInvoice) {
            setSelectedValue(e.target.value)

            setHelperTextUbn('')
            setHelperTextName('')
            setUseLoveCode(false)
            setInputDisable(false)
            setBuyerName('');
            setBuyerUbn('');
            setEmailRequired(true)
            setCarrierTypeValue(carrierTypeDefaultValue)
            setCarrier('')
            setPhoneCarrierHelperText('')
            setCDCCarrierHelperText('')
        } else if (target === businessInvoice) {
            setSelectedValue(e.target.value)

            setUseLoveCode(false)
            setInputDisable(false)
            setEmailRequired(true)
            setCarrierTypeValue(carrierTypeDefaultValue)
            setCarrier('')
            setPhoneCarrierHelperText('')
            setCDCCarrierHelperText('')
        }
    };
    const handleChangeCarrierType = (e) => {
        setCarrierTypeValue(parseInt(e.target.value))
        setCarrier('')
        setPhoneCarrierHelperText('')
        setCDCCarrierHelperText('')
    }
    useEffect(() => {
        isUbnError(buyer_ubn, buyer_name) ? setHelperTextUbn('買受人統一編號，純數字') : setHelperTextUbn('')
        if (!isNameError(buyer_ubn, buyer_name)) {
            setHelperTextName('')
        } else {
            if (buyer_name.length < 2 || buyer_name.length > 60) {
                setHelperTextName('發票抬頭長度限制 60 字元')
            } else {
                setHelperTextName('發票抬頭不可包含數字及特殊字元')
            }
        }
    }, [buyer_ubn, buyer_name])

    useEffect(() => {
        apiPaymentGetLovecodeInfo()
            .then((res) => res.data)
            .then((res) => {
                if (handleRespCode(res) === false) {
                    return
                } else {
                    setLoveCodeInfo(res.name + "(" + res.lovecode + ")")
                }
            })
            .catch((error) => handleAPICall(error))
    }, [])

    function validateForm() {
        if (selectedValue === useLoveCode) {
            return true
        } else if (selectedValue === personalInvoice){
            if (user_email === "") {
                return false
            }
            if (!isEmailValid(user_email)) {
                return false
            }
            if (selectedCarrierTypeValue === CarrierTypeMember) {
                return true
            }
            if (selectedCarrierTypeValue === CarrierTypePhone) {
                if (carrier === "") {
                    return false
                }
                return isValidPhoneCarrier(carrier)
            }
            if (selectedCarrierTypeValue === CarrierTypeCDC) {
                if (carrier === "") {
                    return false
                }
                return isValidCDCCarrier(carrier)
            }
            return true
        } else if (selectedValue === businessInvoice) {
            if (user_email === "") {
                return false
            }
            if (!isEmailValid(user_email)) {
                return false
            }
            if (buyer_ubn === "" || buyer_name === "") {
                return false
            }
            return !isUbnError(buyer_ubn, buyer_name) && !isNameError(buyer_ubn, buyer_name)
        }
    }
    const newebpay_form = useRef(null);
    const newebpay_form_merchantID = useRef(null);
    const newebpay_form_tradeInfo = useRef(null);
    const newebpay_form_tradeSha = useRef(null);
    const newebpay_form_version = useRef(null);
    const newebPayFormSubmit = (data) => {
        newebpay_form_merchantID.current.value = data.MerchantID;
        newebpay_form_tradeInfo.current.value = data.TradeInfo;
        newebpay_form_tradeSha.current.value = data.TradeSha;
        newebpay_form_version.current.value = data.Version;
        newebpay_form.current.submit();
    }
    const onBuySubmit = async (recharge_id) => {
        // empty strings are invalid params, so we only send corresponding params to the server
        let params = {
            recharge_id,
            use_love_code,
        }
        if (!use_love_code) {
            params.user_email = user_email;
            if (buyer_ubn !== '' || buyer_name !== '') {
                params.buyer_ubn = buyer_ubn
                params.buyer_name = buyer_name
            }
            if (selectedValue === personalInvoice) {
                params.carrier_type = selectedCarrierTypeValue
                params.carrier = carrier
            }
        }
        await apiBuyRechargeNewebPay(params)
            .then((res) => res.data)
            .then((res) => {
                if (handleRespCode(res) === false) {
                    return
                } else {
                    newebPayFormSubmit(res.data)
                }
            })
            .catch((error) => handleAPICall(error))
    };
    return (
        <>
                 <Typography className={classes.context}>
                    請選擇發票類型
                 </Typography>
            <Radio
                checked={selectedValue === useLoveCode}
                onChange={handleChangeRadio}
                value={useLoveCode}
                color="default"

            />
            <span className={classes.text}>
                捐贈至
            </span>
            <span className={classes.loveCode}>{loveCodeInfo}</span><br />
            <Radio
                checked={selectedValue === personalInvoice}
                onChange={handleChangeRadio}
                value={personalInvoice}
                color="default"
            />
            <span className={classes.text}>
                個人電子發票
            </span><br />
            <Radio
                checked={selectedValue === businessInvoice}
                onChange={handleChangeRadio}
                label="businessInvoice"
                value={businessInvoice}
                color="default"
            />
            <span className={classes.text}>
                公司發票
            </span><br />
            <form className={classes.form} >
                 {/* <span className={classes.carrierLabel}>
                     請選擇載具類型
                 </span> */}
                 <Select
                    variant="outlined"
                    hidden={selectedValue !== personalInvoice}
                    color="primary"
                    className={classes.select}
                    fullWidth
                    label="載具類型"
                    native
                    defaultValue={carrierTypeDefaultValue}
                    value={selectedCarrierTypeValue}
                    onChange={handleChangeCarrierType}
                    style={{
                        height: '40px',
                        marginLeft: '-0.5px',
                        marginTop: '15px',
                        color: 'black',
                        paddingLeft: '0px',
                    }}
                    inputProps={{
                        name: 'age',
                        id: 'outlined-age-native-simple',
                    }}
                    SelectDisplayProps={{ classes: { paper: classes.select } }}
                    MenuProps={{
                        classes: { paper: classes.select },
                    }}
                >
                  <option value={1}>會員載具(用戶可自行歸戶)</option>
                  <option value={2}>手機載具條碼</option>
                  <option value={3}>自然人憑證</option>
                </Select>
                <TextField
                    hidden={selectedValue !== personalInvoice || selectedCarrierTypeValue !== CarrierTypePhone}
                    required={true}
                    style={{ height: '52px', marginTop: '0px', marginLeft: '1px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    disabled={inputDisable}
                    margin="normal"
                    id="carrier_phone"
                    label={"請輸入手機載具(必填)"}
                    placeholder="範例：/ABC+123"
                    type="name"
                    value={carrier}
                    error={phoneCarrierError}
                    helperText={phoneCarrierHelperText}
                    fullWidth
                    FormHelperTextProps={{
                        className: classes.helperText
                    }}
                    onChange={(e) => {
                        setCarrier(e.target.value)
                        const t = e.target.value
                        if (t === "" || isValidPhoneCarrier(t)) {
                            setPhoneCarrierError(null)
                            setPhoneCarrierHelperText('')
                            return
                        }
                        setPhoneCarrierError(true)
                        setPhoneCarrierHelperText('範例：/ABC+123')
                    }}
                />
                <TextField
                    hidden={selectedValue !== personalInvoice || selectedCarrierTypeValue !== CarrierTypeCDC}
                    required={true}
                    style={{ height: '52px', marginTop: '0px', marginLeft: '1px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    disabled={inputDisable}
                    margin="normal"
                    id="carrier_cdc"
                    label={"請輸入自然人憑證(必填)"}
                    placeholder="16碼英數字，前面兩碼為大寫英文字母"
                    type="name"
                    value={carrier}
                    error={CDCCarrierError}
                    helperText={CDCCarrierHelperText}
                    fullWidth
                    FormHelperTextProps={{
                        className: classes.helperText
                    }}
                    onChange={(e) => {
                        setCarrier(e.target.value)
                        const t = e.target.value
                        if (t === "" || isValidCDCCarrier(t)) {
                            setCDCCarrierError(null)
                            setCDCCarrierHelperText('')
                            return
                        }
                        setCDCCarrierError(true)
                        setCDCCarrierHelperText('16碼英數字，前面兩碼為大寫英文字母')
                    }}
                />
                <TextField
                    hidden={selectedValue === useLoveCode}
                    style={{ height: '50px', marginLeft: '1px', marginTop: '2px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor,
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    required={isEmailRequired}
                    disabled={inputDisable}
                    id={user_email}
                    label="請輸入Email(必填)"
                    type={email}
                    value={user_email}
                    error={!isEmailValid(user_email)}
                    fullWidth
                    onChange={(e) => setUserMail(e.target.value)}
                />

                <TextField
                    hidden={selectedValue !== businessInvoice}
                    required={true}
                    style={{ height: '50px', marginLeft: '1px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    disabled={inputDisable}
                    margin="normal"
                    id="name"
                    label="請輸入統編(必填)"
                    placeholder="買受人統一編號，純數字"
                    type="name"
                    value={buyer_ubn}
                    error={isUbnError(buyer_ubn, buyer_name)}
                    helperText={helperTextUbn}
                    fullWidth
                    FormHelperTextProps={{
                        className: classes.helperText
                    }}
                    onChange={(e) => setBuyerUbn(e.target.value)}
                />
                <TextField
                    hidden={selectedValue !== businessInvoice}
                    required={true}
                    style={{ height: '50px', marginLeft: '1px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    disabled={inputDisable}
                    margin="normal"
                    id="title"
                    label="請輸入發票抬頭(必填)"
                    placeholder="發票抬頭長度限制 60 字元"
                    type="name"
                    value={buyer_name}
                    error={isNameError(buyer_ubn, buyer_name)}
                    helperText={helperTextName}
                    fullWidth
                    FormHelperTextProps={{
                        className: classes.helperText
                    }}
                    onChange={(e) => setBuyerName(e.target.value)}
                />
            </form>

            <Button
                type="submit"
                onClick={() => onBuySubmit(id)}
                disabled={!validateForm()}
                fullWidth
                className={classes.button}
                variant="contained"
            >
                前往付款
            </Button>

            <form id="newebpay_form" ref={newebpay_form} name='newebpay' method='post' action={newWebPayMPGAPI}>
                <input type='hidden' ref={newebpay_form_merchantID} id="merchantid" name='MerchantID' value='' />
                <input type='hidden' ref={newebpay_form_tradeInfo} id="tradeinfo" name='TradeInfo' value='' />
                <input type='hidden' ref={newebpay_form_tradeSha} id="tradesha" name='TradeSha' value='' />
                <input type='hidden' ref={newebpay_form_version} id="version" name='Version' value='1.5' />
            </form>
        </>
    )
};

export default DemoForm;

import { Typography } from '@material-ui/core';
import { css } from '@emotion/css';
import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

const footerCss = css`
  position: relative;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0px 4px 48px 0 rgba(0, 0, 0, 0.69);

  @media only screen and (max-width: 870px) {
    padding: 0 15px;
    }
`;

const copyRightCss = css`
  && {
    font-weight: bold;
    margin-right: 1rem;
  }
`;

const policiesCss = css`
  && {
    font-weight: bold;
    margin-left: 1rem;
    color: white;
    text-decoration: none;
  }
`;

const linkCss = css`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const Footer = () => {
  return (
    <footer className={footerCss}>
      <Typography className={copyRightCss}>瀨芙新創有限公司 &copy;LAIFU XINCHUANG CO.,LTD.</Typography>
      <Typography>|</Typography>
      <NavLink to="/policies" className={linkCss} target="_blank">
        <Typography className={policiesCss}>服務條款</Typography>
      </NavLink>
    </footer>
  );
};

export default memo(Footer);

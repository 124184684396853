import React from 'react';
import { css } from '@emotion/css';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonMap = ({ length, width, height }) => {
    const skeleton = Array.from({ length: length });
    return (<>
        {skeleton.map((id, index) =>
            <Grid key={index} item xs={12} sm={12} >
                <Skeleton
                    key={index}
                    height={height}
                    className={css`
                    border-radius: 5px;
                    max-width: ${width};
                    min-width: 240px;
                `}
                    variant="rect"
                    animation="wave"
                />
            </Grid>
        )}
    </>)
}

export default SkeletonMap;
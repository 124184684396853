import React from "react";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
const theme = createMuiTheme({
    overrides: {
        // 样式表的名字 ⚛️
        MuiOutlinedInput: {
            // 规则的名字
            input: {
                // 一些 CSS
                backgroundColor: '#fff'
            },
        }
    },
});

interface PhoneNumberInputProps {
    error: boolean
    value: string
    textFieldCSS: string
    onChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
}
const PhoneNumberInput = ({ error, value, textFieldCSS, onChange }: PhoneNumberInputProps) => {
    return (
        <ThemeProvider theme={theme}>
            <FormControl variant="outlined" fullWidth className={textFieldCSS}>
                <InputLabel >請輸入手機號碼</InputLabel>
                <OutlinedInput
                    id="21_phone"
                    type="text"
                    autoComplete="21_phone"
                    required
                    error={error}
                    value={value}
                    onChange={onChange}
                    labelWidth={115}
                />
            </FormControl>
        </ThemeProvider>
    )
}
export default PhoneNumberInput;
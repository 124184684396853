export const weChat = 1;
export const facebook = 3;
export const google = 5;
export const apple = 13;
export const passwordLogin = 'passwordLogin';
export const smsLogin = 'smsLogin';
export const resetPassword = 'resetPassword';
export const defaultTitle = '登入 Halu Live';
export const smsLoginTitle = '簡訊驗證碼登入';
export const resetPwdTitle = '重設密碼';
export const wxPurchaseLink = '';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { facebook } from './Const';
const FacebookLoginComponent = ({ thirdPartyLogin, styleProps }) => {
    const responseFacebook = (response) => {
        thirdPartyLogin(facebook, response.id, response.accessToken);
    }
    return (
        <FacebookLogin
            appId='1108387957170645'
            autoLoad={false}
            fields="name,picture"
            scope="public_profile"
            onClick={''}
            callback={responseFacebook}
            version='19.0'
            isMobile={false}
            render={props => (
                <div
                    className={styleProps}
                    style={{ backgroundImage: `url(https://web-cdn.halu.love/images/login/Facebook_80X80.png)` }}
                    onClick={props.onClick}
                />
            )}
        />)
};

export default FacebookLoginComponent;
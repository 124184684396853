import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { css } from '@emotion/css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && children}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
const indicatorCss = css`
  display: flex;
  justify-content: center;
  min-height: 5px;
  && {
    background-color: transparent;
  }
  & > span {
    z-index: 1;
    width: 100%;
    background-color: #01579b;
  }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        border: 0,
        flexGrow: 1,
    },
    // TODO: Fix flex width to let tabs can adjust size automatically
    wideTab: {
        flexGrow: 1,
        color: '#039be5'
    },
    tabBar: {
        backgroundColor: '#ffffff'
    }
}));

export default function EventTabs({ options = [] }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar className={classes.tabBar} position="static">
                <Tabs
                    TabIndicatorProps={{ children: <span /> }}
                    value={value}
                    variant='fullWidth'
                    onChange={handleChange}
                    classes={{ indicator: indicatorCss }}
                >
                    {options.map(option =>
                        <Tab
                            className={classes.wideTab}
                            key={option.label}
                            label={option.label}
                        />)}
                </Tabs>
            </AppBar>
            {options.map((option, index) => <TabPanel key={option.label} value={value} index={index} >{option.component}</TabPanel>)}
        </div>
    );
}
import React, { useState, useEffect } from 'react';
interface CountdownTimerProps {
    sec: number
    timesUp(): void
}
const CountdownTimer = ({ sec, timesUp }: CountdownTimerProps) => {
    const [seconds, setSeconds] = useState(sec);
    useEffect(() => {
        setSeconds(sec)
    }, [sec]);

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        } else if (seconds === 0) {
            timesUp()
            setSeconds(-1)
        }
    }, [seconds, timesUp]);

    return (
        <>{seconds > 0 ? seconds : ''}</>
    )
}
export default CountdownTimer;
import React, { useState } from "react";
import {
    apiThirdPartyLogin,
    handleAPICall,
    setLocalStorageEncrypt,
    accessTokenKey,
    xCsrfTokenKey
} from '../service/api';
import ReactLoading from 'react-loading';
import { makeStyles } from '@material-ui/core/styles';
import PhoneLogin from './PhoneLogin';
import FacebookLogin from './FacebookLogin';
import AppleIdLogin from './AppleIdLogin';
import GoogleLoginButton from './GoogleLogin';
import { useSetUserInfo } from '../UserState';
import { useDispatch } from 'react-redux';
import { setLoginDialogClose, setErrorMsg, setSnackbarOpen } from '../actions';
import { useMediaQuery } from '@material-ui/core';
import {
    passwordLogin,
    smsLogin,
    resetPassword,
    defaultTitle,
    smsLoginTitle,
    resetPwdTitle
} from './Const'
import DialogTitle from './component/DialogTitle';
import { version1PurchaseURL, newebpayOutageURL } from '../service/api';
const useStyles = makeStyles((theme) => ({
    dialogWrap: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '500px',
        minWidth: '300px',
        padding: '2rem',
        [theme.breakpoints.down('xs')]: {
            padding: '1rem',
            minWidth: '256px',
        }
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: '2rem',
        color: 'black'
    },
    placeholder: {
        color: '#cc0000',
        fontSize: '.8rem',
        margin: '1rem 0',
        height: '.8rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '.75rem',
        }
    },
    thirdPartyBtnGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
        padding: '3rem 0',
        [theme.breakpoints.down('xs')]: {
            padding: '1rem 0',
        }
    },
    thirdPartyBtn: {
        cursor: 'pointer',
        boxSizing: 'border-box',
        boxShadow: '0 .2rem .5rem rgba(0, 0, 0, .27)',
        height: '50px',
        width: '50px',
        margin: '0 1rem',
        borderRadius: '50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    noticeText: {
        width: '100%',
        fontSize: '.8rem',
    },
    waitLoginRespLoading: {
        position: 'fixed',
        left: '47%',
        top: '40%',
        width: '100%',
        height: '100%',
        zIndex: '9999',
        background: 'transparent',
        [theme.breakpoints.down('xs')]: {
            left: '38%',
        }
    }
}));

const LoginPage = ({ newebpayOutage }) => {
    const classes = useStyles();
    const desktop = useMediaQuery('(min-width:600px)');
    const [errMsgPlaceholder, setErrMsgPlaceholder] = useState('');
    const [title, setTitle] = useState(defaultTitle);
    const [phoneLoginType, setPhoneLoginType] = useState(passwordLogin);
    const [isWaitingLoginResp, setIsWaitingLoginResp] = useState(false);
    // hide third-party login button when user choose reset password or SMS login type
    // because we want to keep the dialog simple and clear
    const hideThirdPartyLogin = phoneLoginType !== passwordLogin
    const setUserInfo = useSetUserInfo();
    const dispatch = useDispatch();

    const handleCloseLoginPage = () => {
        dispatch(setLoginDialogClose())
    }

    const thirdPartyLogin = async (num, user_name, identity_token) => {
        setIsWaitingLoginResp(true)
        await apiThirdPartyLogin({
            type: num,
            user_name: user_name,
            identity_token: identity_token,
        })
            .then((res) => {
                if (res.data.code === 0) {
                    // save in localStorage
                    setLocalStorageEncrypt(accessTokenKey, res.data.access_token)
                    setLocalStorageEncrypt(xCsrfTokenKey, res.headers[xCsrfTokenKey])
                }
                return res.data
            })
            .then((resp) => {
                if (resp.code === 0) {
                    setUserInfo()
                } else {
                    // open dialog again to show error message
                    setIsWaitingLoginResp(false)
                    dispatch(setSnackbarOpen())
                    dispatch(setErrorMsg(`${resp.msg}`))
                    setErrMsgPlaceholder(`${resp.msg}`)
                }
            })
            .catch((error) => {
                // open dialog again to show error message
                setIsWaitingLoginResp(false)
                handleAPICall(error)
            })
    }
    const handleOnBack = () => {
        setErrMsgPlaceholder('')
        setPhoneLoginType(passwordLogin)
        setTitle(defaultTitle)
    }
    const handleSMSTypeOnClick = () => {
        setErrMsgPlaceholder('')
        setPhoneLoginType(smsLogin)
        setTitle(smsLoginTitle)
    }
    const handleResetPwdTypeOnClick = () => {
        setErrMsgPlaceholder('')
        setPhoneLoginType(resetPassword)
        setTitle(resetPwdTitle)
    }
    return(
        <div>
            {isWaitingLoginResp?
            <div>
                <ReactLoading type="spinningBubbles" color="white" height={80} width={80} className={classes.waitLoginRespLoading}/>
            </div>:
            <div className={classes.dialogWrap}>
                <DialogTitle
                    className={classes.title}
                    showBack={hideThirdPartyLogin}
                    title={title}
                    backOnClick={handleOnBack}
                    onClose={handleCloseLoginPage}
                />
                <PhoneLogin
                    type={phoneLoginType}
                    timesUp={handleOnBack}
                    smsTypeOnClick={handleSMSTypeOnClick}
                    resetPwdTypeOnClick={handleResetPwdTypeOnClick}
                    setErrMsgPlaceholder={setErrMsgPlaceholder}
                    setIsWaitingLoginResp={setIsWaitingLoginResp}
                />
                <div className={classes.placeholder}>
                    {errMsgPlaceholder}
                </div>
                {!hideThirdPartyLogin &&
                    <div className={classes.thirdPartyBtnGroup}>
                        <FacebookLogin
                            styleProps={classes.thirdPartyBtn}
                            thirdPartyLogin={thirdPartyLogin}
                        />
                        <AppleIdLogin
                            styleProps={classes.thirdPartyBtn}
                            thirdPartyLogin={thirdPartyLogin}
                        />
                        <GoogleLoginButton
                            styleProps={classes.thirdPartyBtn}
                            thirdPartyLogin={thirdPartyLogin}
                        />
                    </div>
                }
                {/* <div className={classes.noticeText}>
                    請您提高警覺：&nbsp;Halu Live&nbsp;不會以任何分期付款失敗等名義要求您至 ATM 解除分期付款操作或提供信用卡等資料。
                </div> */}
            </div>
            }
        </div>
    )
};
export default LoginPage;
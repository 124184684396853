import React, { useState, useEffect } from 'react'
import { apiGetGiftsList } from '../../service/api';
import ReactPaginate from 'react-paginate';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { handleAPICall } from '../../service/api';
import { useMediaQuery } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setErrorMsg, setSnackbarOpen } from '../../actions';
import CardSlider from "./CardSlider";
import CardItem from "./CardItem";
import GiftItem from './GiftItem';
import SkeletonMap from '../../components/SkeletonMap';
import { forcePageHint, event } from './const';

const useStyles = makeStyles((theme) => ({
    list_root: {
        flexGrow: 1,
        margin: '0 3rem',
        [theme.breakpoints.down('sm')]: {
            margin: '0 1rem',
        },
    },
    list_wrap: {
        padding: theme.spacing(2),
        maxWidth: 1000,
        margin: 'auto',
        marginBottom: '2rem',
        marginTop: '2rem',
        boxShadow: '1px 1px 6px rgba(177, 177, 177, .6)',
        paddingBottom: '1rem',
    },
    list_paginate: {
        padding: theme.spacing(2),
        maxWidth: 1000,
        margin: 'auto',
        marginBottom: '2rem',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
        }
    },
    pagination: {
        padding: theme.spacing(0),
        display: 'flex',
        justifyContent: 'space-between',
        listStyle: 'none',
        cursor: 'pointer',
        '& a': {
            padding: '10px',
        },
    },
    pagination__link: {
        padding: theme.spacing(1),
        borderRadius: '5px',
        background: '#CBEAF9',
        fontWeight: 700,
        color: '#3e3e3e',
        "& a:hover": {
            textDecoration: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(.5),
            fontSize: '0.7rem',
            display: 'block',
        }
    },
    pagination__link__active: {
        fontWeight: 'bold',
        color: '#039be5',
    },
    pagination__link__disabled: {
        borderRadius: '5px',
        background: '#f3f3f3',
        color: '#3e3e3e',
    },
    top: {
        position: 'fixed',
        bottom: '32px',
        right: '16px',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        background: '#f3f3f3',
        textAlign: 'center',
        lineHeight: '40px',
        cursor: 'pointer',
        zIndex: 20,
        boxShadow: '1px 1px 6px rgba(177, 177, 177, .6)',
        '&:hover': {
            background: '#CBEAF9',
            transition: 'all 0.3s ease-in-out',
        },
        [theme.breakpoints.down('sm')]: {
            bottom: '32px',
            right: '16px',
        }
    },
    breakLabel: {
        color: '#707070',
        position: 'relative',
        '&::after': {
            content: `'${forcePageHint}'`,
            display: 'flex',
            fontSize: '.8rem',
            width: '55px',
            padding: '2px',
            borderRadius: '5px',
            backgroundColor: '#f3f3f3',
            position: 'absolute',
            top: '-16px',
            opacity: '0'
        },
        "&:hover": {
            '&::after': {
                transitionDuration: '.1s',
                transitionTimingFunction: 'ease-in-out',
                opacity: '100'
            },
        },
    },
}))

interface listArray {
    "gift_id": number,
    "name": string,
    "type": number,
    "pic_url": string,
    "gift_image": string,
    "download_url": string,
    "price": number,
    "tag": string,
    "effect_url_array"?: []
}

const Gifts = () => {
    const classes = useStyles()
    const desktop = useMediaQuery('(min-width:600px)');
    const [currentPage, setCurrentPage] = useState(0);
    const [list, setList] = useState<listArray[]>([]);
    const [eventGiftList, setEventGiftList] = useState<listArray[]>([])
    const [isLoad, setIsLoad] = useState<boolean>(true);
    const PER_PAGE = 10;
    const dispatch = useDispatch();

    useEffect(() => {
        apiGetGiftsList()
            .then((res: { data: any; }) => res.data)
            .then((res: { code: number; tabs: { list: listArray[]; }[]; msg: string; }) => {
                if (!res) return
                if (res.tabs.length && res.code === 0) {
                    const dataList = res.tabs[0].list
                    const eventList = dataList
                        .filter(item => item.tag === event)
                        .sort((a: any, b: any) => a.price < b.price ? 1 : -1)

                    setList(dataList)
                    setEventGiftList(eventList)
                    setIsLoad(false)
                    return
                } else {
                    dispatch(setSnackbarOpen())
                    dispatch(setErrorMsg(`${res.msg}`))
                }
            })
            .catch((error: any) => handleAPICall(error))
    }, [dispatch]);

    function handlePageClick({ selected }: { selected: any }) {
        window.scrollTo(0, 400)
        setCurrentPage(selected);
    }

    const offset: number = currentPage * PER_PAGE;

    const currentPageData = list
        .slice(offset, offset + PER_PAGE)
        .map((data, index) => {
            const {
                pic_url: img,
                name: title,
                price: point,
                tag: isEvent,
                gift_id: giftId
            } = data
            return (
                <div key={giftId}>
                    <GiftItem pic_url={img} name={title} price={point} tag={isEvent} />
                </div>
            )
        }
        );
    const pageCount = Math.ceil(list.length / PER_PAGE);

    return (<>
        <div className={classes.list_root}>
            <div className={classes.top}
                style={{ backgroundImage: 'url(https://web-cdn.halu.love/images/gifts/up.png)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
                onClick={() => window.scrollTo(0, 0)} />
            {!isLoad ?
                <CardSlider
                    list={eventGiftList}
                    renderItem={CardItem}
                    width={375}
                    boxWidth={1000}
                    opacity={0.7}
                    scale={0.9}
                    disableNext={false}
                    disablePrev={false}
                    index={0}
                />
                : <div className={classes.list_wrap}>
                    <SkeletonMap
                        length={1}
                        height={400}
                        width={1000}
                    />
                </div>}
            <Paper className={classes.list_wrap}>
                {!isLoad ? currentPageData
                    :
                    <SkeletonMap
                        length={6}
                        height={70}
                        width={1000}
                    />}
            </Paper>
            <div className={classes.list_paginate}>
                <ReactPaginate
                    previousLabel={desktop ? "< 上一頁" : " < "}
                    previousClassName={classes.pagination__link}
                    nextLabel={desktop ? "下一頁 >" : " > "}
                    nextClassName={classes.pagination__link}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={classes.pagination}
                    disabledClassName={classes.pagination__link__disabled}
                    activeClassName={classes.pagination__link__active}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    disableInitialCallback={true}
                    initialPage={currentPage}
                    breakClassName={classes.breakLabel}
                />
            </div>
        </div>
    </>)
}

export default Gifts;
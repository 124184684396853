import React, { memo, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import SkeletonMap from '../../components/SkeletonMap';
import { apiGetEvnet } from '../../service/api';
import Card from './Card';
import { makeStyles } from '@material-ui/core/styles';
import EventBanner from './EventBanner';
const useStyle = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    margin: '1rem',
    color: '#FEDC62',
    fontSize: '1.2em',
  },
  eventStatus: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: '1rem',
    fontSize: '1rem',
    color: 'white',
    lineHeight: '50px',
    '&::before': {
      content: '""',
      display: 'block',
      height: 1,
      marginTop: -5,
      top: 0,
      left: 0,
      right: 0,
      background: '#f3f3f3',
    }
  },
})
);

const Loading = () => {
  return (
    <SkeletonMap
      data-testid="isLoad"
      length={2}
      width={649}
      height={216}
    />);
};
const timeToDate = (t) => {
  // time format: 20201127000000
  return new Date(t.replace(
    /(\d\d\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)/, '$1-$2-$3T$4:$5:$6'
  ));
}
const Events = () => {
  const classes = useStyle();
  const [unStartedEvents, setUnstartedEvents] = useState([]);
  const [onProgressEvents, setOnProgressEvents] = useState([]);
  const [endedEvents, setEndedEvents] = useState([]);
  const [isLoad, setIsLoad] = useState(true);

  useEffect(() => {
    apiGetEvnet().then((res) => {
      const now = Date.now();
      const unStartedEventArr = res.data.activity_list
        .filter(event => timeToDate(event.start_time) > now)
        .sort((a, b) => a.id < b.id ? 1 : -1)
      const onProgressEventArr = res.data.activity_list
        .filter(event => timeToDate(event.end_time) > now && timeToDate(event.start_time) < now)
        .sort((a, b) => a.id < b.id ? 1 : -1)
      const endedEventArr = res.data.activity_list
        .filter(event => timeToDate(event.end_time) < now)
        .sort((a, b) => a.id < b.id ? 1 : -1)

      setUnstartedEvents(unStartedEventArr);
      setOnProgressEvents(onProgressEventArr);
      setEndedEvents(endedEventArr);
      setIsLoad(false)
    });
  }, []);
  const hasUnstartedEvent = unStartedEvents.length > 0
  const hasOnProgressEvent = onProgressEvents.length > 0
  const UnstartedEventList = () => {
    return unStartedEvents
      .map((event, index) => {
        const {
          activity_title: title,
          image_url: imgURL,
          jump_url: jumpURL,
        } = event;
        return (
          <Grid key={index} item xs={12} sm={12} md={6}>
            <Card id={event.id} title={title} imgURL={imgURL} jumpURL={jumpURL}></Card>
          </Grid>
        );
      });
  };

  const OnProgressEventList = () => {
    return onProgressEvents
      .map((event, index) => {
        const {
          activity_title: title,
          image_url: imgURL,
          jump_url: jumpURL,
        } = event;
        return (
          <Grid key={index} item xs={12} sm={12} md={6}>
            <Card id={event.id} title={title} imgURL={imgURL} jumpURL={jumpURL}></Card>
          </Grid>
        );
      });
  };

  const EndedEventList = () => {
    return endedEvents
      .map((event, index) => {
        const {
          activity_title: title,
          image_url: imgURL,
          jump_url: jumpURL,
        } = event;
        return (
          <Grid key={index} item xs={12} sm={12} md={6}>
            <Card id={event.id} title={title} imgURL={imgURL} jumpURL={jumpURL}></Card>
          </Grid>
        );
      });
  }
  return (
    <div>
      <EventBanner
        skeletonWidth={1200}
        skeletonHeight={300}
        carouselMaxWidth={1200}
      />
      <Container>
        <div>
          <h2 className={classes.title}>活動列表</h2>
          <div className={classes.box} />
        </div>
        {hasUnstartedEvent ? <h2 className={classes.eventStatus}>尚未開始的活動</h2> : ''}
        <Grid container spacing={2} data-testid="unStarted" >
          {!isLoad ? <UnstartedEventList /> : <Loading />}
        </Grid>

        {hasOnProgressEvent ? <h2 className={classes.eventStatus}>進行中的活動</h2>: ''}
        <Grid container spacing={2} data-testid="onProgress">
          {!isLoad ? <OnProgressEventList /> : <Loading />}
        </Grid>

        <h2 className={classes.box}> </h2>

        <h2 className={classes.eventStatus}>已結束的活動</h2>
        <Grid container spacing={2} data-testid="ended" >
          {!isLoad ? <EndedEventList /> : <Loading />}
        </Grid>

      </Container>
    </div>
  );
};


export default memo(Events);

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CountdownTimer from '../components/CountdownTimer';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    img: {
        width: '90px',
    },
    content: {
        marginTop: '2rem',
        textAlign: 'center',
        color: '#707070',
    }
}));

interface DoneProps {
    label: string
    timesUp(): void
}
const Done = ({ label, timesUp }: DoneProps) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <img className={classes.img} alt="done" src="https://web-cdn.halu.love/images/login/check-mark.png" />
            <div className={classes.content}>
                {label}<br />
                <CountdownTimer sec={3} timesUp={timesUp} />
                秒後自動跳轉
            </div>
        </div>)
}
export default Done
import React, { memo, useState, useEffect, useCallback } from 'react';

import { css } from '@emotion/css';
import SkeletonMap from '../../../components/SkeletonMap';

import { apiGetActivity, apiGetIncomeDayRank } from '../../../service/api';
import TabPanel from './TabPanel'
import { Images, Leaderboard, Reminders } from './List'
import { rankCount, streamerRank, fansRank, eventBeforeStart, eventStarted, eventEnded } from './Const';


const pageCss = css`
    max-width: 750px;
    min-height: 100vh;
    margin: auto;
    background: #f3f3f3;
`;

const pageFont = css`
    font-size: 1rem;
    color: #9db9c5;
    text-align:center;
    margin: auto;
    padding: 1rem 0;
`;

const countDownCss = css`
    color: #cc0000;
`;

const timeToDate = (t) => {
    // time format: 20201127000000
    return new Date(t.replace(
        /(\d\d\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)/, '$1-$2-$3T$4:$5:$6'
    ));
}

const EventPeriod = ({ startDate, endDate }) => {
    const startTime = startDate.toISOString().replace("T", " ").substring(0, "2020-11-11T08:57:09".length);
    const endTime = endDate.toISOString().replace("T", " ").substring(0, "2020-11-11T08:57:09".length);
    return (
        <div className={pageFont}>{startTime} ~ {endTime}</div>
    )
}

const EventCountDown = ({ status, timeLeft }) => {
    if (status === eventBeforeStart) {
        return (<div className={pageFont}>
            <p>❤ 活動尚未開始，敬請期待 ❤</p>
        </div>
        )
    } else if (status === eventEnded) {
        return (<div className={pageFont}>
            ❤ 活動已結束 ❤
        </div>
        )
    } else {
        return (<div className={pageFont}>
            活動尚餘 <span className={countDownCss}>{timeLeft.days}</span> 天 <span className={countDownCss}>{timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}</span>
        </div>
        )
    }
}

const calculateTimeLeft = (endDate) => {
    let diff = +endDate - +new Date();
    if (diff < 0) {
        diff = 0;
    }
    const timeLeft = {
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((diff / 1000 / 60) % 60),
        seconds: Math.floor((diff / 1000) % 60)
    };
    return timeLeft;
}

function BasicPage({ eventID, options }) {
    const [event, setEvent] = useState({ status: "", startDate: "", endDate: "", });
    const [isEventLoad, setIsEventLoad] = useState(false);
    const [streamerRanks, setStreamerRanks] = useState([]);
    const [isStreamerRankLoad, setIsStreamerRankLoad] = useState(false);
    const [fansRanks, setFansRanks] = useState([]);
    const [isFansRankLoad, setIsFansRankLoad] = useState(false);
    const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    const getEvent = useCallback(async () => {
        const resp = await apiGetActivity(eventID);

        let status = "";
        const startDate = timeToDate(resp.data.activity.start_time);
        const endDate = timeToDate(resp.data.activity.end_time);
        const now = new Date();
        if (+now - +startDate < 0) {
            status = eventBeforeStart;
        } else if (+now - +endDate > 0) {
            status = eventEnded;
        } else {
            status = eventStarted;
            // TODO: update status and stop counting down when the event is end
            setTimeLeft(calculateTimeLeft(endDate));
            setInterval(() => {
                setTimeLeft(calculateTimeLeft(endDate));
            }, 1000);
        }
        setEvent({
            status: status,
            startDate: startDate,
            endDate: endDate,
        });
        setIsEventLoad(true);
    }, [eventID])

    const getIncomeRank = useCallback(async (type) => {
        const resp = await apiGetIncomeDayRank({
            params: {
                activity_id: eventID,
                ranking_type: type,
                index: 0,
                count: rankCount,
            },
        });
        const ranks = JSON.parse(resp.data.substring(resp.data.indexOf('(') + 1, resp.data.indexOf(')'))).list;
        if (type === streamerRank) {
            setStreamerRanks(ranks);
            setIsStreamerRankLoad(true);
        } else if (type === fansRank) {
            setFansRanks(ranks);
            setIsFansRankLoad(true);
        }
    }, [eventID])
    useEffect(() => {
        getEvent();
        getIncomeRank(streamerRank);
        if (options.enableFansRank) {
            getIncomeRank(fansRank);
        }
    }, [getEvent, getIncomeRank, options.enableFansRank]);

    if (isEventLoad) {
        let panelOptions = [];
        if (isStreamerRankLoad) {
            panelOptions = [
                { label: '主播榜單', component: <Leaderboard ranks={streamerRanks} /> },
            ]
            if (isFansRankLoad) {
                panelOptions = [...panelOptions, { label: '粉絲榜單', component: <Leaderboard ranks={fansRanks} /> }];
            }
            panelOptions = [...panelOptions, { label: '提醒', component: <Reminders /> }];
        }
        return (
            <div className={pageCss}>
                <Images images={options.images} />
                <EventPeriod startDate={event.startDate} endDate={event.endDate} />
                <EventCountDown status={event.status} timeLeft={timeLeft} />
                {event.status !== eventBeforeStart && isStreamerRankLoad ? <TabPanel options={panelOptions} /> : <div></div>}
            </div>
        );
    } else {
        return (
            <SkeletonMap
                length={8}
                width={649}
                height={216}
            />);
    }
}

export default memo(BasicPage);
import React from "react";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { css } from '@emotion/css';

const hasTagCss = css`
        display: inline-block;
        text-align: left;
        background: #0A579B;
        padding: 5px 10px;
        color: #fff;
        margin: 0 3rem;
        position: relative;
        border: 1px solid #fff;
        top: -50px;
        left: -108px;
        @media only screen and (max-width: 870px){
            margin: 0 4rem;
        }`;
const styles: { [name: string]: React.CSSProperties } = {
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: '0px',
        margin: 'auto',
        maxWidth: '100%',
        background: '#f3f3f3',
        color: '#0A579B',
    },
    img: {
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '300px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    content: {
        padding: '0px',
    },
    price: {
        display: 'flex',
    },
};
type ItemProp = {
    name: string,
    pic_url: string,
    price: string,
    style?: object,
}
const CardItem: React.FC<ItemProp> = ({ name, pic_url, price, style }) => {
    return (
        <div key={pic_url}
            style={{
                width: 300,
                textAlign: "center",
                borderRadius: '15px',
                ...style
            }}>
            <Card style={{ ...styles.root }}>
                <CardContent style={{ ...styles.paper }}>
                    <div style={{ background: '#fff' }}>
                        <CardMedia
                            style={{ ...styles.img }}
                            component="img"
                            alt={name}
                            height="300px"
                            image={pic_url}
                        />
                    </div>
                    <div className={hasTagCss}>
                        活動限定
                    </div>
                    <CardContent style={{ ...styles.content }}>
                        <Typography gutterBottom variant="h5" component="h2">
                            {name}
                        </Typography>
                        <Typography variant="subtitle1" component="p" style={{ paddingBottom: '24px' }}>
                            <img src={'https://web-cdn.halu.love/images/purchase/pint_50x50.png'} alt='shell_ic' />
                            {price}
                        </Typography>
                    </CardContent>
                </CardContent>
            </Card>
        </div>
    )
}

export default CardItem;

import * as React from "react";

export interface CardProps {
    opacity: number;
    scale: number;
    loop?: boolean;
    width: number;
    disablePrev?: boolean;
    disableNext?: boolean;
    boxWidth: number;
    index?: number;
    list: any[];
    renderItem(data?: object): React.ReactNode;
    onChange?(index: number, data: any): any;
    style?: React.CSSProperties;
}
interface CardState {
    activeIndex: number;
    moving: boolean;
}
export default class CardSlider extends React.Component<CardProps, CardState> {
    static defaultProps: Partial<CardProps> = {
        opacity: 0.9,
        scale: 0.9,
        loop: true,
        disablePrev: false,
        disableNext: false
    };

    constructor(props: CardProps) {
        super(props);
        this.state = {
            activeIndex: props.index || 0,
            moving: true
        };
    }

    componentWillReceiveProps(nextProps: any) {
        if (this.props.index !== nextProps.index) {
            this.setState({
                activeIndex: nextProps.index
            });
        }
    }
    // 卡片总数量
    get totalCount() {
        return this.props.list.length;
    }

    // 间隔宽度
    get gridWidth() {
        const isEven = this.totalCount % 2 === 0;
        const { width, boxWidth } = this.props;
        return (
            (boxWidth - width) / (isEven ? this.totalCount : this.totalCount - 2)
        );
    }

    // 禁用prev
    get disablePrev() {
        const { loop, disablePrev } = this.props;
        const { activeIndex } = this.state;
        if (disablePrev) return true;
        return !loop && activeIndex === 0;
    }

    // 禁用next
    get disableNext() {
        const { loop, disableNext } = this.props;
        const { activeIndex } = this.state;
        if (disableNext) return true;
        return !loop && activeIndex === this.totalCount - 1;
    }

    /**
     * offset: 是左或者右的第几个
     * direction: 1:右侧：-1：左侧
     */
    getDirection(index: number) {
        const { activeIndex } = this.state;
        let direction = 1;
        if (
            index - activeIndex > this.totalCount / 2 ||
            (index - activeIndex < 0 && index - activeIndex > -this.totalCount / 2)
        ) {
            direction = -1;
        }

        let offset = Math.abs(index - activeIndex);
        if (offset > this.totalCount / 2) {
            offset = activeIndex + this.totalCount - index;
        }
        if (index - activeIndex < -this.totalCount / 2) {
            offset = this.totalCount + index - activeIndex;
        }
        return {
            direction,
            offset
        };
    }

    render() {
        const {
            list,
            renderItem,
            opacity,
            scale,
            width,
            boxWidth
        } = this.props;
        return (
            <div style={{ ...styles.wrapper }}>
                <div style={{ ...styles.content, width: boxWidth, padding: '1rem' }}>
                    {list.map((data, index) => {
                        const { direction, offset } = this.getDirection(index);
                        const realScale = Math.pow(scale, offset);
                        return renderItem({
                            key: index,
                            ...data,
                            style: {
                                position: "absolute",
                                left: "50%",
                                marginLeft:
                                    this.gridWidth * direction * offset +
                                    direction * ((width / 2) * (1 - realScale)),
                                zIndex: this.totalCount - offset,
                                opacity: Math.pow(opacity, offset),
                                transform: `translateX(-50%) translateZ(0) scale(${realScale})`,
                                transition: "all 300ms ease-in-out"
                            }
                        });
                    })}
                </div>
                {
                    !this.disablePrev && (
                        <div
                            style={{ ...styles.btn, left: 1 }}
                            onClick={this.handlePrev}
                        >
                            {"<"}
                        </div>
                    )
                }
                {
                    !this.disableNext && (
                        <div
                            style={{ ...styles.btn, right: 1 }}
                            onClick={this.handleNext}
                        >
                            {">"}
                        </div>
                    )
                }
            </div >
        );
    }

    handlePrev = () => {
        let { activeIndex } = this.state;
        if (this.disablePrev) return;
        activeIndex = --activeIndex < 0 ? this.totalCount - 1 : activeIndex;
        this.setState({ activeIndex });
        this.handleChange(activeIndex);
    };

    handleNext = () => {
        let { activeIndex } = this.state;
        if (this.disableNext) return;
        activeIndex = ++activeIndex >= this.totalCount ? 0 : activeIndex;
        this.setState({ activeIndex });
        this.handleChange(activeIndex);
    };
    handleChange = (index: number) => {
        const { list, onChange } = this.props;
        onChange && onChange(index, list[index]);
    };
}

const styles: { [name: string]: React.CSSProperties } = {
    wrapper: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        margin: 'auto',
        overflow: 'hidden',
    },

    content: {
        height: '450px',
        position: "relative",
        marginBottom: '2rem',
    },

    // 箭头图标自行解决
    btn: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 36,
        height: 36,
        zIndex: 99,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 24,
        cursor: 'pointer'
    }
};
// ref: https://codesandbox.io/s/53p38q9rjl

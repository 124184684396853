import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import loadScriptOnceSync from './LoadScriptOnceSync';

class WXLogin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            scriptLoaded: false,
        }
    }
    async componentDidMount() {
        await this.initScript()
        this.onOpenLogin()
        let that = this
        window.receiveMessageFromIndex = function (event) {
            if (event !== undefined) {
                // 发送请求 event.data为微信信息对象 为对象类型再请求接口
                if (typeof event.data === 'object') {
                    let WXCode = event.data.code
                    // 没有code或已发请求 不重复请求，isLogining为是否在登录中判断值
                    if (!WXCode || that.props.isLogining)
                        return
                    let body = {
                        code: WXCode,
                    }
                    // 执行项目的登录流程
                    that.props.loginWX(body)
                }
            }
        }
        //监听来自iframe的message事件
        window.addEventListener('message', window.receiveMessageFromIndex, false)
    }

    initScript = () => {
        const WXLOGINJS_URL = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
        loadScriptOnceSync(
            WXLOGINJS_URL,
            () => this.setState({ scriptLoaded: true })
        )
    }
    onOpenLogin = () => {
        const timer = setTimeout(() => {
            this.initWX()
            clearTimeout(timer)
        }, 2000)
    }
    initWX() {
        new window.WxLogin({
            id: 'login_container',
            appid: 'wx9144660822b4b22a',
            scope: 'snsapi_login',
            redirect_uri: encodeURIComponent('https://halu.love/wxlogin_response'), // 扫码成功后重定向地址
            response_type: 'code',
            state: 'isLogin',
            style: 'black',
            href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7d2lkdGg6IDIwMHB4O30NCi5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0NCi5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9"
        })
        this.setState({ isLoading: false })
    }

    componentWillUnmount() {
        window.removeEventListener('message', window.receiveMessageFromIndex)
    }
    render() {
        const { isLoading } = this.state
        return (
            <div
                style={{
                    height: '80vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                {isLoading ?
                    <>
                        <CircularProgress />
                        <p>正在獲取資料</p>
                    </>
                    : <></>
                }
                <div className={'styles.wx_container'} onClick={this.onOpenLogin}>
                    <div id="login_container" />
                </div>
            </div>
        )
    }
}

export default WXLogin;
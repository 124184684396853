import React from 'react'
import { css } from '@emotion/css';

const list = css`
    padding: 15px;
    padding-left: 2rem;
    margin: 0 auto;
    color: #707070;
`

const item = css`
    position: relative;
    display: -webkit-box;
    align-items: center;
    padding: 0.5rem;
    height: 4.5rem;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    background: white;
    transform-origin: left;
    cursor: pointer;
    transition: transform 200ms ease-in-out;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
`;

const itemPos = css`
    margin: 0.1rem;
    font-weight: 900;
    position: absolute;
    left: -2rem;
    text-align: center;
    font-size: 1.25rem;
    width: 1.5rem;
    color: #3e3e3e;
    transition: opacity 200ms ease-in-out;
`;

const itemPic = css`
    box-sizing: border-box;
	width: 3rem;
    height: 3rem;
    border-radius: 999em;
    border: 1px solid #c3c3c3;
    background-size: cover;
    background-position: center;
    box-shadow:
    0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.6);
`;

const itemName = css`
    -webkit-box-flex: 2;
    flex-grow: 2;
    flex-basis: 10rem;
    font-size: 1.1rem;
    opacity: 0.9;
    margin-left: 1rem;
`;

const itemScore = css`
	content: 'points';
    color: #f0aa00;
    width: 25%;
    display: flex;
    justify-content: flex-end;
    text-align: right;
`;

export const Leaderboard = ({ ranks = [] }) => {
    return (
        <div className={list}>
            {ranks.map((rank, index) =>
                <div key={rank.order} className={item}>
                    <div className={itemPos}>{rank.order}</div>
                    <div className={itemPic} style={{ backgroundImage: `url(${rank.pic_head_low === "" ? "https://web-cdn.halu.love/images/general/newLOGO192.png" : rank.pic_head_low})` }}></div>
                    <div className={itemName}>{rank.nickname}</div>
                    <div className={itemScore}>{rank.ticket_count === undefined ? "" : rank.ticket_count + " Point"}</div>
                </div>
            )}
        </div>
    )
}

const reminderCss = css`
  margin-top: 15px;
  padding: 5px;
  color: #707070;
  opacity: 0.9;

  @media (min-width: 375px) {
    font-size: 1rem;
  }

  @media (min-width: 1366px) {
    font-size: 1rem;
  }
`;

export const Reminders = () => {
    const reminders = [
        "官方不允許非法之『活動獎勵的交易』及『除本平台外購點以及盜刷行為』與干擾比賽結果自刷點數行為。",
        "若經過查證發現官方平台有權利視情節『回收點數』『取消得獎資格』以及『回收所有獎勵』，並且保有法律追訴權。",
        "若經査證屬實惡意刷退信用卡消費者，本平台有權抵扣以及追回直播主該名惡意刷退者所分得之利益。",
        "活動排名以及結算以活動時間頁面為主，預期收送活動禮物一律不計算，榜單若有分數相同之情況取優先達到排名為得獎者。",
        "所有獎項依台灣『所得稅法』之規定辦理，由本平台開立扣繳憑單，獎項價值將記入得獎者年度個人所得，並且依法申報及自行負擔稅金。",
        "此活動本官方平台保有修改與變更活動之權利。",
        "在此聲明Apple不是該比賽、活動的主辦、協辦廠商與贊助商。",
        "此活動需蒐集指定禮物，活動榜以指定禮物做累積數據。",
    ]
    return (
        <div>
            {reminders.map((content, index) =>
                <p key={index} className={reminderCss}><span>{index + 1}.</span>{content}</p>
            )}
        </div>
    );
}

export const Images = ({ images = [] }) => {
    return (
        images.map((image, index) => {
            return (
                <div key={index}><img src={image} width="100%" alt="" /></div>
            )
        })
    )
}
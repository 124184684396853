import AppleSignin from 'react-apple-signin-auth';
import { isExpired, decodeToken } from "react-jwt";
import { apple } from './Const';
import { handleAPICall } from '../service/api';

const AppleIdLogin = ({ thirdPartyLogin, styleProps }) => {
    const appleIdOnsuccess = (res) => {
        const id_token = res.authorization.id_token;
        const myDecodedToken = decodeToken(id_token);
        const isMyTokenExpired = isExpired(res);
        if (isMyTokenExpired === true) {
            thirdPartyLogin(apple, myDecodedToken.sub, id_token)
        }
    }
    return (
        <AppleSignin
            authOptions={{
                clientId: 'love.halu',
                scope: 'name email',
                redirectURI: window._env_.REDIRECT_URL,
                state: 'state',
                nonce: 'nonce',
                usePopup: true
            }}
            onSuccess={(response) => appleIdOnsuccess(response)}
            onError={(error) => handleAPICall(error)}
            render={(props) => <div className={styleProps} style={{ backgroundImage: `url(https://web-cdn.halu.love/images/login/Apple_80X80.png)` }} onClick={props.onClick} />}
        />
    )
}

export default AppleIdLogin
import React, { memo, useState } from 'react';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
    marginRight: '1rem',
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: '.5rem',
  },
  name_like: {
    display: 'flex',
    flexDirection: 'column',
    color: '#707070',
  },
  like: {
    fontSize: '.7rem',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: '25px',
  },
  about_me: {
    color: '#707070',
    fontSize: '.8rem',
    display: 'flex',
    flexDirection: 'column',
    verticalAlign: 'middle',
    textAlign: 'left',
    width: '100%',
    maxWidth: '300px',
    overflowY: 'scroll',
    maxHeight: '280px',
    padding: '5px',
  },
  more: {
    color: '#a5a5a5',
    border: 'none',
    backgroundColor: '#fff',
    fontSize: '.5rem',
    padding: '5px 5px 0 5px',
  },
}));

interface UserInfoProps {
  pic_url?: string
  user_name?: string
  like_count?: number
  about_me: string
}
type ShowLoadMoreBtnProp = {
  isShow: boolean
  isClamped: boolean
  onClick(): void
}
const ShowLoadMoreBtn = ({ isShow, isClamped, onClick }: ShowLoadMoreBtnProp) => {
  const classes = useStyles();
  return (!isShow ? <></> :
    <button className={classes.more} onClick={onClick}>
      {isClamped ? '展開' : '收起'}
    </button>
  )
}

const UserInfo = ({ pic_url, user_name, like_count, about_me }: UserInfoProps) => {
  const classes = useStyles();
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
  const [isClamped, setIsClamped] = useState(true);
  return (<>
    <div className={classes.userInfo}>
      <Avatar src={pic_url} className={classes.small} />
      <div className={classes.name_like}>
        <div>{user_name}</div>
        <div className={classes.like}>
          <img
            className={classes.icon}
            src='https://web-cdn.halu.love/images/liveroom/like@2x.png'
            alt="like"
          />
          {like_count}
        </div>
      </div>
    </div>
    <div className={classes.about_me}>
      <ResponsiveEllipsis
        text={about_me ? about_me : '寫下你所想的吧！'}
        isClamped={() => about_me.length > 60}
        maxLine={isClamped ? 3 : 50}
        ellipsis='...'
        basedOn='letters'
      />
    </div>
    <ShowLoadMoreBtn
      isShow={about_me !== undefined && about_me.length > 60}
      isClamped={isClamped}
      onClick={() => setIsClamped(!isClamped)}
    />
  </>)
}
export default memo(UserInfo);
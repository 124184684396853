import React, { memo, useState, useEffect } from 'react';
import { css } from '@emotion/css';
import SkeletonMap from '../../components/SkeletonMap';
import { handleAPICall } from '../../service/api';

const cardCss = css`
  margin: 20px;
  background: white;
  border-radius: 1em;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.6);
`;
const linkCss = css`
  text-decoration: none;
  color: #3e3e3e;
  display: flex;
  flex-direction: column;

  > img {
    border-radius: 1em 1em 0em 0em;
    width: 100%;
    min-width: 240px;
    min-height: 120px;
    background: #f3f3f3;
  }
  > div {
    margin-left: 2em;
  }
`;

const Card = ({ id, title, imgURL, jumpURL }) => {
  const [isLoad, setIsLoad] = useState(true);
  var url = jumpURL;
  if (url === "") {
    url = "events/" + id;
  }
  useEffect(() => {
    if (title !== '' && url !== '') {
      setIsLoad(false)
    }
    const image = new Image();
    image.onload = () => setIsLoad(false);
    image.onerror = (e) => handleAPICall(e);
    image.src = imgURL;
    return () => {
      image.onload = null;
    };
  }, [title, url, imgURL]);

  return (
    <>
      {!isLoad ? (
        <div className={cardCss}>
          <a className={linkCss} href={url} target="_blank" rel="noopener noreferrer">
            <img src={imgURL} alt="img" />
            <div>{title}</div>
          </a>
        </div>
      ) : (
          <SkeletonMap
            length={1}
            height={216}
            width={649}
          />
        )
      }
    </>
  );
};

export default memo(Card);
